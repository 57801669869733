import React from 'react';
import { Link } from 'react-router-dom';
import { FaDiceTwo, FaDice } from 'react-icons/fa';
import { RxColorWheel } from 'react-icons/rx';
import { GiOppositeHearts } from 'react-icons/gi';
import { FaUpDown } from 'react-icons/fa6';
import { PiCoinDuotone } from "react-icons/pi";
import { GiCardPlay } from "react-icons/gi";
import {
  Box,
  Heading,
  Text,
  Center,
  keyframes,
  Flex,
  LinkBox,
  LinkOverlay,
  useColorModeValue
} from '@chakra-ui/react';

const textAnimation = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
`;

const hoverAnimation = keyframes`
  0%, 100% { transform: rotate(0); }
  50% { transform: rotate(10deg); }
`;

const pulseAnimation = keyframes`
   0% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); border-radius: 50%; }
  70% { box-shadow: 0 0 0 20px rgba(0, 0, 0, 0); border-radius: 0%; }
  100% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); border-radius: 50%; }
`;
const headingGradient = {
  bgGradient: 'linear(to-r, #E49BFF, black, black)',
  bgClip: 'text',
};

const cardsData = [
  { id: 1, icon: <RxColorWheel />, color: 'linear(to-r, #C40C0C,black)', title: 'Spin & Win', link: '/single', iconColor: '#FFD700', textColor: '#FFD700' },
  { id: 2, icon: <FaDiceTwo />, color: 'linear(to-r, #973131,black)', title: 'Duo Digits', link: '/double', iconColor: '#BB9AB1', textColor: '#BB9AB1' },
  { id: 3, icon: <GiOppositeHearts />, color: 'linear(to-r, #FF6191, black)', title: 'Colour Shuffle', link: '/colours', iconColor: 'pink', textColor: 'pink' },
  { id: 4, icon: <FaDice />, color: 'linear(to-r, #BB9AB1,black)', title: 'Duo Dice', link: '/Dicerolling', iconColor: 'orange', textColor: 'orange' },
  { id: 5, icon: <FaUpDown />, color: 'linear(to-r, #88D66C, black)', title: 'HighLow', link: '/poker', iconColor: '#32CD32', textColor: '#32CD32' },
  // { id: 6, icon: <PiCoinDuotone/>, color: 'linear(to-r,#B4D6CD, black)', title: 'Coin Toss', link: '/coingame', iconColor: 'black', textColor: 'black' },
  // { id: 7, icon: < GiCardPlay/>, color: 'linear(to-r, #03346E,black)', title: 'Scratch & Win', link: '/scratchcard', iconColor: '#FF4500', textColor: '#FF4500' },
];

const Card = ({ icon, color, title, link, iconColor, textColor }) => (
  <LinkBox
    as="article"
    w={{ base: '200px', md: '250px' }}
    h={{ base: '250px', md: '300px' }}
    m={4}
    borderRadius="20px"
    overflow="hidden"
    boxShadow="2xl"
    transition="transform 0.3s ease, box-shadow 0.3s ease"
    _hover={{ transform: 'translateY(-10px) rotate(3deg)', boxShadow: 'lg', animation: `${hoverAnimation} 0.6s ease-in-out` }}
    bgGradient={color}
    position="relative"
    textAlign="center"
  >
    <Box
      w="full"
      h="full"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="relative"
      zIndex={1}
      color={textColor}
    >
      <Box as="span" fontSize={{ base: '3em', md: '4em' }} animation={`${pulseAnimation} 2s infinite`} color={iconColor}>
        {icon}
      </Box>
      <Text fontSize={{ base: '1.2em', md: '1.5em' }} mt={4} fontWeight="bold">
        {title}
      </Text>
    </Box>
    <LinkOverlay as={Link} to={link} w="full" h="full" position="absolute" top="0" left="0" zIndex={2} />
    <Box
      position="absolute"
      top="0"
      left="0"
      w="full"
      h="full"
      bg="rgba(0, 0, 0, 0.3)"
      borderRadius="20px"
      opacity="0"
      transition="opacity 0.3s ease"
      _hover={{ opacity: 1 }}
    />
  </LinkBox>
);

const CardList = () => {
  return (
    <Box textAlign="center" bg={useColorModeValue('#201E43', '#1A202C')} py={10} minH="90vh">
      <Center mb={8}>
        <Heading
          fontSize={{ base: '30px', md: '50px' }}
          animation={`${textAnimation} 3s ease-in-out infinite`}
          {...headingGradient}
        >
          Game{' '}
          <Text as="span" ml="2px" color="pink.500" bgGradient="linear(to-r, black, black)" bgClip="text">
            Zone
          </Text>
        </Heading>
      </Center>
      <Flex justify="center" flexWrap="wrap">
        {cardsData.map(card => (
          <Card
            key={card.id}
            icon={card.icon}
            color={card.color}
            title={card.title}
            link={card.link}
            iconColor={card.iconColor}
            textColor={card.textColor}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default CardList;
