import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  Divider,
  Heading,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  useColorModeValue,
  Checkbox,
  IconButton,
  Spinner,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext"; // Correct import statement

const Register = () => {
  const { register } = useAuth(); // Use register from AuthContext
  const [username, setUsername] = useState("");
  const [mobile, setMobile] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState("");
  const [isWhatsApp, setIsWhatsApp] = useState(false);
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, username: "" }));
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setMobile(value);
      setErrors((prevErrors) => ({ ...prevErrors, mobile: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    setPasswordStrength(checkPasswordStrength(newPassword));
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const errors = {};

    if (!username) {
      errors.username = "Username is required";
    } else if (username.length < 3) {
      errors.username = "Username must be at least 3 characters long";
    } else if (username.length > 20) {
      errors.username = "Username cannot exceed 20 characters";
    } else if (!/^[a-zA-Z0-9]+$/.test(username)) {
      errors.username = "Username can only contain letters and numbers";
    }

    if (!mobile) {
      errors.mobile = "Mobile number is required";
    } else if (mobile.length !== 10) {
      errors.mobile = "Mobile number must be 10 digits long";
    } else if (!/^\d{10}$/.test(mobile)) {
      errors.mobile = "Invalid mobile number format";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    } else if (password.length > 128) {
      errors.password = "Password cannot exceed 128 characters";
    }

    return errors;
  };

  const checkPasswordStrength = (password) => {
    if (password.length < 6) {
      return "Weak";
    } else if (password.length < 10) {
      return "Medium";
    } else {
      return "Strong";
    }
  };

  const handleSignupFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setLoading(false);
      return;
    }

    try {
      await register(username, mobile, password);

      setShowSuccessAlert(true);
      setAlertMessage("Signup successful!");
      setTimeout(() => {
        setShowSuccessAlert(false);
        navigate("/"); // Navigate to homepage after hiding the success alert
      }, 800);
    } catch (error) {
      console.error("Signup error:", error);
      setShowErrorAlert(true);
      setAlertMessage("An error occurred during signup.");
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      maxW={{ base: "100%", sm: "400px" }}
      mx="auto"
      mt={{ base: "4", sm: "8" }}
      p="6"
      height="auto"
      marginBottom="40px"
      borderRadius="lg"
      boxShadow="md"
      bg={useColorModeValue("white", "gray.800")}
    >
      <Heading as="h2" size="lg" textAlign="center" mb="6" color="#001F3F">
        Sign up
      </Heading>
      <form onSubmit={handleSignupFormSubmit}>
        <VStack spacing="4">
          <FormControl isRequired>
            <FormLabel color="#001F3F">Username</FormLabel>
            <Input
              type="text"
                id="username-input"
              placeholder="Username"
              value={username}
              onChange={handleUsernameChange}
              isInvalid={errors.username}
            />
            {errors.username && (
              <Text fontSize="sm" color="red.500">
                {errors.username}
              </Text>
            )}
          </FormControl>

          <FormControl isRequired>
            <FormLabel color="#001F3F">Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                id="password-input"
                value={password}
                onChange={handlePasswordChange}
                isInvalid={errors.password}
              />
              <InputRightElement>
                <IconButton
                  aria-label={showPassword ? "Hide password" : "Show password"}
                  onClick={handlePasswordVisibility}
                  icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                  variant="ghost"
                />
              </InputRightElement>
            </InputGroup>
            {password && (
              <Text fontSize="sm" color="gray.500">
                Password Strength: {passwordStrength}
              </Text>
            )}
            {errors.password && (
              <Text fontSize="sm" color="red.500">
                {errors.password}
              </Text>
            )}
          </FormControl>

          <FormControl isRequired>
            <FormLabel color="#001F3F">Mobile Number</FormLabel>
            <InputGroup>
              <InputLeftAddon children="+91" />
              <Input
                type="text"
                id="mobile-input"
                placeholder="Mobile Number"
                value={mobile}
                onChange={handleMobileChange}
                isInvalid={errors.mobile}
                maxLength={10}
              />
            </InputGroup>
            <Text fontSize="sm" color="gray.500">
              Please enter a 10-digit mobile number.
            </Text>
            {errors.mobile && (
              <Text fontSize="sm" color="red.500">
                {errors.mobile}
              </Text>
            )}
            <Checkbox
              colorScheme="blue"
              isChecked={isWhatsApp}
              color="#001F3F"
              mt={'3'}
              onChange={() => setIsWhatsApp(!isWhatsApp)}
            >
              Subscribe for updates on WhatsApp
            </Checkbox>
          </FormControl>

          <Button
            type="submit"
            color="white" fontSize="18px" bg="#001F3F"
            width="full"
            isLoading={loading}
            spinnerPlacement='start'
            loadingText="Signing up"
          >
            {loading ? <Spinner size="md" /> : "Sign Up"}
          </Button>

          <Center>
            <Text color="gray.600">
              Already have an account?{" "}
              <Link to="/login"  >
                <Button variant="link" style={{ color: '#001F3F', fontWeight: "bold", textDecoration: 'underline' }} >
                  Login
                </Button>
              </Link>
            </Text>
          </Center>
        </VStack>
      </form>
      {showSuccessAlert && (
        <Alert status="success" mt="4" borderRadius="md">
          <AlertIcon />
          <AlertTitle>{alertMessage}</AlertTitle>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setShowSuccessAlert(false)}
          />
        </Alert>
      )}
      {showErrorAlert && (
        <Alert status="error" mt="4" borderRadius="md">
          <AlertIcon />
          <AlertTitle>{alertMessage}</AlertTitle>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setShowErrorAlert(false)}
          />
        </Alert>
      )}
      <Divider my="6" />
      <Text textAlign="center" color="gray.500" fontSize="sm">
        &copy; {new Date().getFullYear()} VeloDice. All rights reserved.
      </Text>
    </Box>
  );
};

export default Register;
