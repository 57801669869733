import React from 'react';
import { Text, Flex, Skeleton } from '@chakra-ui/react';
import useWalletBalance from './Games/hook/useWalletBalance'; // Adjust the import path as necessary

const Balance = () => {
  const { walletBalance, loading } = useWalletBalance();

  return (
    <Flex alignItems="center" width="100%" justifyContent="center">
      <Skeleton isLoaded={!loading} height="20px" width="35px" startColor="#B0E57C" endColor="#B0E57C">
        <Flex alignItems="center" justifyContent="center">
          <Text fontSize="sm" fontWeight={'bold'} color="#B0E57C">
          {walletBalance !== null ? walletBalance : 0}
          </Text>
        </Flex>
      </Skeleton>
    </Flex>
  );
};

export default Balance;
