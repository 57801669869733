import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  Button,
  VStack,
  Center,
  IconButton,
  Icon,
  keyframes,
} from '@chakra-ui/react';
import { FaStar } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { FaTelegramPlane, FaPlay } from 'react-icons/fa';
import WelcomeBanner from './Welcome';
import './Style/HomePage.css';
import StaticCard from './StaticCard';
import ContactCard from './EnquiryCard';
import CardList from './gameCard';
import ScrollButton from './ScrollButton';

// Define keyframes for the animation
const textAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const headingGradient = {
  bgGradient: 'linear(to-r, #ff0080, #ff8c00, #ffd700)',
  bgClip: 'text',
};

// Define keyframes for animations
const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const glow = keyframes`
  0% {
    box-shadow: 0 0 5px #d2122e, 0 0 10px #d2122e, 0 0 20px #d2122e, 0 0 40px #d2122e;
  }
  100% {
    box-shadow: 0 0 10px #d2122e, 0 0 20px #d2122e, 0 0 40px #d2122e, 0 0 80px #d2122e;
  }
`;

const HeroBanner = () => {
  return (
    <Box
      className='bannerbg'
      py={{ base: '20px', md: '50px' }}
      px={{ base: '20px', md: '10%' }}
      boxShadow="lg"
    >
      <Center flexDirection={{ base: 'column', md: 'row' }} alignItems={{ base: 'center', md: 'flex-start' }}>
        <Box
          w={{ base: '80%', md: '50%', lg: '45%' }}
          maxH={{ base: '300px', md: '500px', lg: '600px' }}
          overflow="hidden"
        >
          <Image
            src="/images/Untitled_design-removebg-preview.png"
            w="100%"
            maxH={{ base: '300px', md: '500px', lg: '700px' }}
          />
        </Box>
        <VStack
          spacing={{ base: '4', md: '6' }}
          alignItems={{ base: 'center', md: 'flex-start' }}
          pl={{ base: '0', md: '4rem' }}
          textAlign={{ base: 'center', md: 'left' }}
          mt={{ base: '4', md: '0' }}
        >
          <Heading
            as="h1"
            fontSize={{ base: '2xl', md: '4xl', lg: '6xl' }}
            fontWeight="extrabold"
            textShadow="2px 2px 4px rgba(0,0,0,0.2)"
            color="yellow"
            mb={{ base: '2', md: '4' }}
            animation={`${bounce} 2s infinite`}
          >
            Play, Predict & Win!
          </Heading>
          <Heading
            fontSize={{ base: 'xl', md: '2xl', lg: '4xl' }}
            fontWeight="bold"
            textShadow="2px 2px 4px rgba(0,0,0,0.2)"
            color="blue.300"
            mb={{ base: '2', md: '4' }}
            animation={`${bounce} 2s infinite 0.5s`}
          >
            Sign Up and Start Winning Today!
          </Heading>
          <Link to="/Dicerolling">
            <Button
              bg='white'
              variant="solid"
              size="lg"
              fontWeight="bold"
              borderRadius="full"
              boxShadow="lg"
              color='#d2122e'
              _hover={{ transform: 'scale(1.1)', boxShadow: 'xl' }}
              _focus={{ outline: "none" }}
              animation={`${glow} 1.5s infinite alternate`}
              leftIcon={<FaPlay />}
            >
              Start Playing
            </Button>
          </Link>
        </VStack>
      </Center>
    </Box>
  );
};


const PricingCard = ({ title, price }) => (
  <Box
    border="2px solid transparent"
    borderRadius="lg"
    boxShadow="md"
    p={5}
    textAlign="center"
    transition="transform 0.2s, border-color 0.3s"
    _hover={{ transform: 'scale(1.05)', borderColor: '#FF8C8C', boxShadow: 'xl' }}
    position="relative"
    overflow="hidden"
    bg="white"
    color="black"
  >
    <Box
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      borderRadius="lg"
      bg="rgba(255, 203, 203, 0.2)"
      zIndex="-1"
      transition="background 0.3s"
      _hover={{ background: 'rgba(255, 203, 203, 0.4)' }}
    />
    <Text
      fontSize={['18px', '22px', '26px']}
      fontWeight="bold"
      color="transparent"
      bgGradient="linear(to-r, pink, black)"
      bgClip="text"
      textShadow="0 0 5px rgba(255, 203, 203, 0.8)"
      pb={2}
      borderBottom="2px solid #FF8C8C"
    >
      {title}
    </Text>
    <Text
      fontSize={['16px', '20px', '20px']}
      mt={3}
      fontWeight="medium"
      color="green.500"
    >
      {price}
    </Text>
  </Box>
);

function HomePage() {
  return (
    <>
      <WelcomeBanner />
      <HeroBanner />
      <Box
        w="100%"
        p={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, black, silver)"
        textAlign="center"
        position="relative"
        overflow="hidden"
        boxShadow="lg"
        sx={{
          animation: "fadeIn 1.5s ease-in-out",
          '@keyframes fadeIn': {
            from: { opacity: 0 },
            to: { opacity: 1 },
          },
        }}
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bgGradient="linear(to-b, rgba(0,0,0,0.1), rgba(0,0,0,0.5))"
        />

        <Box
          overflow="hidden"
          whiteSpace="nowrap"
          sx={{
            display: "inline-flex",
            animation: {
              base: "scroll 10s linear infinite",
              md: "none",
            },
            '@keyframes scroll': {
              '0%': { transform: 'translateX(100%)' },
              '100%': { transform: 'translateX(-100%)' },
            },
          }}
        >
          <Text
            fontSize={{ base: "lg", md: "4xl" }}
            color="gold"
            fontWeight="extrabold"
            mb={2}
            textShadow="0 0 5px #FFD700"
            sx={{
              display: "inline-flex",
              alignItems: "center",
              color: {
                base: "gold",
                md: "gold",
              },
              animation: {
                md: "wave 2s infinite",
              },
              '@keyframes wave': {
                '0%, 100%': {
                  transform: 'translateX(0) translateY(0)',
                },
                '20%': {
                  transform: 'translateX(-5px) translateY(-5px)',
                },
                '40%': {
                  transform: 'translateX(5px) translateY(5px)',
                },
                '60%': {
                  transform: 'translateX(-5px) translateY(5px)',
                },
                '80%': {
                  transform: 'translateX(5px) translateY(-5px)',
                },
              },
            }}
          >
            <Icon as={FaStar} color="gold" mx={2} />
            🎉 Claim Your 100 Bonus Points on First Deposit! 🎉
            <Icon as={FaStar} color="gold" mx={2} />
          </Text>
        </Box>
      </Box>
      <StaticCard />
      <Box
        width="auto"
        height={['auto']}
        textAlign="center"
        mx="auto"
        bg="#FFCBCB"
        py={['20px', '40px', '80px']}
      
        boxShadow="lg"
      >
        <Center mt={['10px', '10px', '20px']}>
          <Heading
            fontSize={['30px', '30px', '40px']}
            animation={`${textAnimation} 3s ease-in-out infinite`}
            {...headingGradient}
          >
            Game{' '}
            <Text
              as="span"
              ml="2px"
              color="pink.500"
              bgGradient="linear(to-r, black, pink.500)"
              bgClip="text"
            >
              Rates ₹
            </Text>
          </Heading>
        </Center>
        <Flex
          flexDirection={['column', 'column', 'column', 'row']}
          justifyContent="center"
          gap={['20px', '20px', '20px', '40px']}
          mt={['20px', '30px', '50px']}
          mx={['20px', '20px', 'auto']}
          maxW="1200px"
        >
          <PricingCard title="Spin & Win" price="Bet ₹10, Win ₹90" />
          <PricingCard title="Duo Digits" price="Bet ₹10, Win ₹900" />
          <PricingCard title="Colour Shuffle" price="Bet ₹10, Win ₹20" />
          <PricingCard title="Duo Dice" price="Bet ₹10, Win ₹90" />
          <PricingCard title="HighLow" price="Bet ₹10, Win ₹20" />
        </Flex>
      </Box>
      <CardList />
      <Box
        position="fixed"
        bottom={{ base: '80px', md: '80px' }}
        right={{ base: '10px', md: '10px' }}
        zIndex="999"
      >
        <Link isExternal>
          <IconButton
            aria-label="Telegram Channel"
            icon={<FaTelegramPlane />}
            backgroundColor="#0088cc"
            color="white"
            borderRadius="50%"
            width={{ base: '40px', md: '45px' }}
            height={{ base: '40px', md: '45px' }}
            boxShadow="md"
            _hover={{ backgroundColor: '#006699' }}
            onClick={() => window.location.href = 'https://t.me/velodice'}
          />
        </Link>
      </Box>
      <ContactCard />
      <ScrollButton />
    </>
  );
}

export default HomePage;
