import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import App from './App';
import theme from './theme';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './Components/AuthContext';
import { WebSocketProvider } from './Components/Games/hook/WebSocketContext';

// Initialize QueryClient
const queryClient = new QueryClient();

// Suppress console logs and errors in production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
}

// Create root element and render the app
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ChakraProvider >
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <WebSocketProvider>
            <App />
          </WebSocketProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// Measure performance in the app
reportWebVitals();
