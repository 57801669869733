import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  Divider,
  Heading,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  InputGroup,
  InputRightElement,
  IconButton,
  Spinner, // Import Spinner
} from "@chakra-ui/react";
import { useNavigate, Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../AuthContext"; // Import useAuth

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();
  const { login } = useAuth(); // Use login function from AuthContext

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, username: "" }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const errors = {};

    if (!username) {
      errors.username = "Username is required";
    } else if (username.length < 3) {
      errors.username = "Username must be at least 3 characters long";
    } else if (username.length > 20) {
      errors.username = "Username cannot exceed 20 characters";
    } else if (!/^[a-zA-Z0-9]+$/.test(username)) {
      errors.username = "Username can only contain letters and numbers";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    } else if (password.length > 128) {
      errors.password = "Password cannot exceed 128 characters";
    }

    return errors;
  };

  const handleLoginFormSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setLoading(true); // Set loading to true when starting the request

    try {
      const response = await fetch("https://newproject3-0-hem2.onrender.com/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok && data.status === "ok") {
        localStorage.setItem("token", data.token);

        const decodedToken = jwtDecode(data.token);
        const { name, username } = decodedToken;

        localStorage.setItem("authenticated", "true");
        localStorage.setItem("user", JSON.stringify({ name, username }));

        login({ name, username }); // Update user context on login
        showSuccess("Login successful!");
      } else {
        showError(data.error || "Invalid username or password.");
      }
    } catch (error) {
      console.error("Login error:", error);
      showError("An error occurred during login.");
    } finally {
      setLoading(false); // Set loading to false after the request completes
    }
  };

  const showSuccess = (message) => {
    setAlertMessage(message);
    setShowSuccessAlert(true);
    setTimeout(() => {
      setShowSuccessAlert(false);
      navigate("/");
    },300);
  };

  const showError = (message) => {
    setAlertMessage(message);
    setShowErrorAlert(true);
    setTimeout(() => setShowErrorAlert(false), 3000);
  };

  return (
    <Box
      maxW={{ base: "100%", sm: "400px" }}
      mx="auto"
      mt="8"
      p="6"
      height="450px"
      mb="40px"
      boxShadow="xl"
      bg="white"
      _hover={{ transform: "scale(1.01)" }}
      transition="all 0.3s ease"
    >
      <Heading as="h2" size="lg" textAlign="center" mb="4" color="#001F3F">
        Login
      </Heading>
      <form onSubmit={handleLoginFormSubmit}>
        <VStack spacing="4">
          <FormControl isRequired isInvalid={!!errors.username}>
            <FormLabel color="#001F3F">Username</FormLabel>
            <Input
              type="text"
              placeholder="Username"
              value={username}
              color="black"
              onChange={handleUsernameChange}
            />
            {errors.username && <Text color="red">{errors.username}</Text>}
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.password}>
            <FormLabel color="#001F3F">Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                color="black"
                value={password}
                onChange={handlePasswordChange}
              />
              <InputRightElement width="3rem">
                <IconButton
                  aria-label={showPassword ? "Hide password" : "Show password"}
                  onClick={handlePasswordVisibility}
                  icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                  variant="ghost"
                />
              </InputRightElement>
            </InputGroup>
            {errors.password && <Text color="red">{errors.password}</Text>}
          </FormControl>
          <Button type="submit" color="white" fontSize="18px" bg="#001F3F" w="100%" position="relative" disabled={loading}>
            {loading && (
              <Spinner
                size="sm"
                color="white"
                position="absolute"
                left="50%"
                top="50%"
                transform="translate(-50%, -50%)"
              />
            )}
            Login
          </Button>
        </VStack>
      </form>
      <Divider my="6" borderColor="white" />
      <Center>
        <Text color="gray.600">
          Don't have an account? <Link style={{ color: '#001F3F', fontWeight: "bold", textDecoration: 'underline' }} to="/signup">Sign Up</Link>
        </Text>
      </Center>
      <Center>
        <Text style={{ color: '#001F3F', fontWeight: "bold", textDecoration: 'underline' }}>
          <Link to="/forgot-password">Forgot Password</Link>
        </Text>
      </Center>

      {/* Success Alert */}
      {showSuccessAlert && (
        <Alert status="success" mb="4">
          <AlertIcon />
          <AlertTitle mr={2}>{alertMessage}</AlertTitle>
          <CloseButton onClick={() => setShowSuccessAlert(false)} />
        </Alert>
      )}

      {/* Error Alert */}
      {showErrorAlert && (
        <Alert status="error" mb="4">
          <AlertIcon />
          <AlertTitle mr={2}>{alertMessage}</AlertTitle>
          <CloseButton onClick={() => setShowErrorAlert(false)} />
        </Alert>
      )}
    </Box>
  );
};

export default Login;
