import React from 'react';
import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// SocialIcon Component
function SocialIcon({ icon, label, href }) {
  return (
    <Link
      to={href}
      style={{ display: 'flex', alignItems: 'center', color:'grey', padding: '8px', fontSize: '20px' }}
    >
      {icon}
      <Text ml={2} fontSize={{ base: 'sm', md: 'md' }}>
        {label}
      </Text>
    </Link>
  );
}

// Footer Component
function Footer() {
  const gradientTextStyle = {
    background: 'linear-gradient(90deg, rgba(226,107,189,1) 0%, rgba(210,106,198,1) 31%, rgba(117,102,254,1) 79%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    color: 'transparent',
  };

  return (
    <Box
      bg="#040404"
      py={5}
      color={useColorModeValue('white', '#000000')}
      width="100%"
      marginBottom="-26px"
    >
      <Container maxW="6xl">
        <Stack
          direction={{ base: 'column', md: 'row' }}
          align="center"
          justify="space-between"
          spacing={{ base: 4, md: 6 }}
        >
          <Box>
            <Link to="/" fontWeight="bold">
              <Image
                src="/images/logo-no-background.png"
                alt="Logo"
                width={{ base: '120px', md: '180px' }}
                height="auto"
              />
            </Link>
          </Box>
          <Stack spacing={4} align="center">
            <Text fontWeight="bold" fontSize={{ base: 'sm', md: 'md' }} style={gradientTextStyle}>
              Company
            </Text>
            <Stack spacing={2} textAlign="center">
              <Link to="/gamerules" style={gradientTextStyle}>Game Rules</Link>
              <Link to="/aboutus" style={gradientTextStyle}>About us</Link>
              <Link to="/terms" style={gradientTextStyle}>Terms & Conditions</Link>
            </Stack>
          </Stack>
          <Stack spacing={4} align="center">
            <Text fontWeight="bold" fontSize={{ base: 'sm', md: 'md' }} style={gradientTextStyle}>
              Stay Connected
            </Text>
            <Stack direction="row" spacing={0} align="center">
              <SocialIcon icon={<FaTwitter />} href="/" />
              <SocialIcon icon={<FaYoutube />} href="/" />
              <SocialIcon icon={<FaInstagram />} href="/" />
            </Stack>
          </Stack>
        </Stack>
        <Text textAlign="center" style={gradientTextStyle} fontSize={{ base: 'xs', md: 'sm' }} mt={4}>
          Copyright © 2024 VeloDice. All rights reserved
        </Text>
      </Container>
    </Box>
  );
}

// Main Layout Component
function Layout({ children }) {
  return (
    <Flex direction="column" minHeight="100vh">
      {/* Header or Navbar can be added here */}
      <Box flex="1">{children}</Box>
      <Footer />
    </Flex>
  );
}

export default Layout;
