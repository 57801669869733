import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './Components/NavbarPage';
import Register from './Components/Auth/Register';
import Login from './Components/Auth/Login';
import HomePage from './Components/HomePage';
import AboutUs from './Components/AboutUs';
import HowtoPlay from './Components/HowtoPlay';
import TermAndConditions from './Components/TermAndConditions';
import Withdraw from './Components/Withdraw';
import Deposit from './Components/Deposit';
import BetHistory from './Components/BetHistory';
import SingleNumber from './Components/Games/SingleNumber';
import JodiNumber from './Components/Games/JodiNumber';
import DiceGame from './Components/Games/Dice';
import DiceHighLowGame from './Components/Games/Poker';
import BankDetails from './Components/BankDetails';
import WalletTransactions from './Components/Statement';
import ForgetPassword from './Components/Auth/ForgetPassword';
import CreditTransaction from './Components/Credit';
import DebitTransaction from './Components/Debit';
import ColorPredictionGame from './Components/Games/ColourGame';
import Layout from './Components/FooterPage';
import NotificationDetails from './Components/Notification';
import HelpPage from './Components/HelpPage';
import { useAuth } from './Components/AuthContext';
// import CoinGame from './Components/Games/CoinGame';
// import ScratchCardGame from './Components/Games/Scratchcard';
// import VideoPage from './Components/VedioPage';
const ProtectedRoute = ({ component }) => {
  const { user } = useAuth();

  if (!user) {
    // Redirect unauthenticated users to login page
    return <Navigate to="/login" />;
  }

  return component;
};
function App() {
  return (
    <Router>
      <Navbar />
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/gamerules" element={<HowtoPlay />} />
          <Route path="/terms" element={<TermAndConditions />} />
          <Route path="/notification" element={<NotificationDetails />} />
          <Route path="/single" element={<SingleNumber />} />
          <Route path="/double" element={<JodiNumber />} />
          <Route path="/colours" element={<ColorPredictionGame />} />
          <Route path="/Dicerolling" element={<DiceGame />} />
          <Route path="/poker" element={<DiceHighLowGame />} />
          <Route path="/Help" element={<HelpPage />} />
          <Route path="/withdraw" element={<ProtectedRoute component={<Withdraw />} />} />
          <Route path="/addbank" element={<ProtectedRoute component={<BankDetails />} />} />
          <Route path="/deposit" element={<ProtectedRoute component={<Deposit />} />} />
          <Route path="/bethistory" element={<ProtectedRoute component={<BetHistory />} />} />
          <Route path="/statement" element={<ProtectedRoute component={<WalletTransactions />} />} />
          <Route path="/credit" element={<ProtectedRoute component={<CreditTransaction />} />} />
          <Route path="/debit" element={<ProtectedRoute component={<DebitTransaction />} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Register />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          {/* <Route path="/Vedio" element={<VideoPage />} /> */}
          {/* <Route path="/coingame" element={<CoinGame />} />
          <Route path="/scratchcard" element={<ScratchCardGame />} /> */}
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
