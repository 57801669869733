import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  ModalCloseButton,
  Icon,
  Input,
  useToast,
  Center
} from '@chakra-ui/react';
import { keyframes } from "@emotion/react";

import { DeleteIcon } from '@chakra-ui/icons';
import { FaGamepad, FaPlus, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import useWalletBalance from './hook/useWalletBalance';
import useWalletActions from './hook/useWalletActions';
import slotMachineSound from './slot-machine-payout-81725.mp3';
import { jwtDecode } from 'jwt-decode';


// Pulsing animation
const pulse = keyframes`
  0% {
    transform: scale(1);
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.7);
  }
  50% {
    transform: scale(1.05);
    text-shadow: 0 0 15px rgba(0, 255, 255, 0.7);
  }
  100% {
    transform: scale(1);
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.7);
  }
`;

// Styles for the timer text with pulsing animation
const timerStyles = {
  fontSize: "5xl",
  fontWeight: "bold",
  color: " #FF6969",
  textAlign: "center",
  animation: `${pulse} 1.5s ease-in-out infinite`,
};

const JodiNumberGame = () => {
  const { colorMode } = useColorMode();
  const { walletBalance, loading } = useWalletBalance();
  const { withdrawFromWallet, depositToWallet } = useWalletActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [suggestions, setSuggestions] = useState([]);
  const [selectedBets, setSelectedBets] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [username, setUsername] = useState('');
  const [selectedNumber, setSelectedNumber] = useState('');
  const [betAmount, setBetAmount] = useState('');
  const [result, setResult] = useState(null);
  const [audio] = useState(new Audio(slotMachineSound));
  const [rolling, setRolling] = useState(false);
  const [timer, setTimer] = useState(0); // Timer state
  const [timerInterval, setTimerInterval] = useState(null); // Timer interval state
  const [winningAmount, setWinningAmount] = useState(0);
  const [message, setMessage] = useState('');
  const [muted, setMuted] = useState(false);
  const toast = useToast();

  useEffect(() => {
    audio.load();
  }, [audio]);

  const playSlotMachineSound = () => {
    audio.currentTime = 0;
    audio.play();
  };

  useEffect(() => {
    if (rolling && !muted) {
      playSlotMachineSound();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [rolling, muted]);

  const toggleMute = () => {
    const newMutedState = !muted;
    setMuted(newMutedState);

    if (newMutedState) {
      audio.pause();
    } else if (rolling) {
      audio.play();
    }
  };

  useEffect(() => {
    let animationInterval;
  
    if (rolling) {
      // Start rolling animation
      animationInterval = setInterval(() => {
        setResult(generateRandomNumber());
      }, 50);
  
      // Start timer
      setTimer(10);
      const interval = setInterval(() => {
        setTimer(prev => {
          if (prev <= 1) {
            clearInterval(interval);
            setRolling(false);
            audio.pause();
            audio.currentTime = 0;
            return 0;
          }
          return prev - 1;
        });
      }, 1000); // Update interval to 1 second
      setTimerInterval(interval);
  
      // Ensure continuous audio play
      audio.loop = true;
      if (!muted) {
        audio.play();
      }
    } else {
      clearInterval(animationInterval);
      clearInterval(timerInterval);
    }
  
    return () => {
      clearInterval(animationInterval);
      clearInterval(timerInterval);
      audio.pause();
      audio.currentTime = 0;
    };
  }, [rolling, muted]);
  

  useEffect(() => {
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (rolling) {
        event.preventDefault();
        event.returnValue = 'You have an ongoing game. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [rolling]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const usernameFromToken = decodedToken.username;
      setUsername(usernameFromToken);
    }
  }, []);

  const handleBet = async () => {
    onClose();
    if (selectedBets.length > 0) {
      const totalBetAmount = selectedBets.reduce((acc, bet) => acc + bet.amount, 0);

      if (walletBalance < totalBetAmount) {
        toast({
          title: 'Insufficient Balance',
          description: 'You do not have enough balance to place this bet.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      await withdrawFromWallet(totalBetAmount, true);
      setRolling(true);

      // Set a timeout for the game to stop after 10 seconds
      setTimeout(() => {
        const randomNumber = generateRandomNumber();
        setResult(randomNumber);
        setRolling(false);

        const winnings = calculateWinnings(randomNumber);

        if (winnings > 0) {
          setMessage(`Congratulations! You won ${winnings}₹`);
          setWinningAmount(winnings);
          updateBackendBetResult(true, winnings, totalBetAmount, selectedBets);
          depositToWallet(winnings, true);

          toast({
            title: 'Congratulations!',
            description: `You won ${winnings}₹`,
            status: 'success',
            duration: 2000,
            isClosable: true,
            position: 'top',
          });
        } else {
          setMessage('Better luck next time!');
          updateBackendBetResult(false, 0, totalBetAmount, selectedBets);

          toast({
            title: 'Better luck next time!',
            description: 'You lost the bet.',
            status: 'error',
            duration: 2000,
            isClosable: true,
            position: 'bottom',
          });
        }

        setSelectedBets([]);
      }, 10000); // Ensure the game stops exactly after 10 seconds
    } else {
      toast({
        title: 'Enter Amount.',
        description: 'Please enter a valid bet amount and select at least one number.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const placeBetConfirmation = () => {
    if (selectedBets.length > 0) {
      onOpen();
    } else {
      toast({
        title: 'No Bets Selected',
        description: 'Please select at least one bet.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleBetAmountChange = (e) => {
    const amount = e.target.value.replace(/[^\d]/, ''); // Allow only numeric values
    setBetAmount(amount);
  };
  const handleAddBet = () => {
    if (selectedNumber !== '' && betAmount !== '' && parseInt(betAmount) > 0) {
      const betNumber = parseInt(selectedNumber);
      const existingBet = selectedBets.find(bet => bet.number === betNumber);
      if (!existingBet) {
        const newBet = { number: betNumber, amount: parseInt(betAmount) };
        setSelectedBets(prevBets => [...prevBets, newBet]);
        setBetAmount('');
        setSelectedNumber('');
      } else {
        toast({
          title: 'Duplicate Number.',
          description: 'This number is already bet on. Please choose another number.',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: 'Invalid Bet',
        description: 'Please enter a valid number and bet amount.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNumberChange = (e) => {
    const { value } = e.target;
    if (/^\d{0,2}$/.test(value)) {
      if (value.length === 1) {
        // Generate suggestions for single digit input
        const newSuggestions = Array.from({ length: 100 }, (_, i) => `${value}${i < 10 ? `${i}` : i}`);
        setSuggestions(newSuggestions.filter((item) => item.startsWith(value)));
        setSelectedNumber(value);
      } else if (value.length === 2) {
        setSelectedNumber(value);
        setSuggestions([]);
      } else {
        setSelectedNumber('');
        setSuggestions([]);
      }
    }

    // Disable suggestions on mobile screens
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    if (isMobile) {
      setSuggestions([]);
    }
  };


  const handleSuggestionClick = (suggestion) => {
    setSelectedNumber(suggestion);
    setSuggestions([]);
  };

  const handleDeleteBet = index => {
    const updatedBets = [...selectedBets];
    updatedBets.splice(index, 1);
    setSelectedBets(updatedBets);
  };

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 100);
  };

  const randomNumber1 = rolling ? Math.floor(Math.random() * 10) : Math.floor(result / 10);
  const randomNumber2 = rolling ? Math.floor(Math.random() * 10) : result % 10;

  const calculateWinnings = randomNumber => {
    const winningBet = selectedBets.find(bet => bet.number === randomNumber);
    if (winningBet) {
      return winningBet.amount * 9;
    }
    return 0;
  };

  const rollNumbers = () => {
    const currentNumber = Math.floor(Math.random() * 100);
    setResult(currentNumber); // Display rolling number
  };
  const updateBackendBetResult = async (isWin, winningAmount, totalBetAmount, selectedBets) => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        // Map selectedBets to the required format for the backend
        const selectedNumbersArray = selectedBets.map(bet => ({
          number: bet.number,
          betAmount: bet.amount, // Ensure the individual bet amount is taken from selectedBets
        }));

        const response = await fetch('https://newproject3-0-hem2.onrender.com/account/bet', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            username,
            betAmount: totalBetAmount,
            gameName: 'Duo Digits',
            category: 'Slot',
            betResult: isWin ? 'Win' : 'Loss',
            selectedNumbers: selectedNumbersArray,
            winningAmount,
          }),
        });

        if (!response.ok) {
          const data = await response.json();
          toast({
            title: 'Error',
            description: data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error placing bet:', error.message);
      }
    }
  };

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      background="linear-gradient(to bottom, #FF6969, #FFB1B1)"
      backdropFilter="blur(10px)" // Blur effect
      fontSize={['sm', 'md', 'lg', 'xl']} // Responsive font size
    >
      <Box
        p={{ base: '4', md: '8' }}
        w={{ base: '90%', md: '80%', lg: '70%' }}
        maxW="1500px"
        borderRadius="md"
        color={colorMode === 'dark' ? 'white' : 'gray.800'}
        position="relative" // Position relative for animated background
        overflow="hidden" // Hide overflow to contain animated background
      >
        <Heading
          as="h1"
          mb={{ base: '6', md: '10' }}
          textAlign="center"
          color="black.500"
          fontSize={{ base: '4xl', md: '6xl' }}
          fontWeight="bold"
          textShadow="2px 2px 4px rgba(0, 0, 0, 0.2)" // Text shadow
        >
          Duo Digits
        </Heading>
        {/* Animated background */}
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex="-1"
          overflow="hidden"
        >
          <svg
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMinYMin slice"
          >
            <circle cx="50" cy="50" r="20" fill="#FFB1B1">
              <animate
                attributeName="cx"
                values="50; 150; 50"
                dur="4s"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="100" cy="100" r="30" fill="#FF6969">
              <animate
                attributeName="cy"
                values="100; 50; 100"
                dur="3s"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="150" cy="50" r="20" fill="#FFB1B1">
              <animate
                attributeName="cx"
                values="150; 50; 150"
                dur="5s"
                repeatCount="indefinite"
              />
            </circle>
          </svg>
        </Box>
        <Flex justifyContent="center" alignItems="center" gap="5" mb="4">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bg="linear-gradient(to right, #181818, #181818)"
            width="120px"
            height="180px"
            border="4px solid"
            borderColor="#333"
            borderRadius="md"
            overflow="hidden"
            position="relative"
            transformStyle="preserve-3d"
            perspective="1000px"
          >
            <Text
              fontSize="9xl"
              fontWeight="bold"
              color="#FF6969"
              position="absolute"
              top="50%"
              transform="translateY(-50%) translateZ(40px)"
            >
              {rolling ? Math.floor(Math.random() * 10) : randomNumber1}
            </Text>
            {rolling && (
              <Box
                position="absolute"
                width="100%"
                height="100%"
                bg="linear-gradient(to right, #181818, #4b4b4b)"
                animation={`spin${randomNumber1} 3s ease-out infinite`}
                zIndex="-1"
              />
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bg="linear-gradient(to right, #181818, #181818)"
            width="120px"
            height="180px"
            border="4px solid"
            borderColor="#333"
            borderRadius="md"
            overflow="hidden"
            position="relative"
            transformStyle="preserve-3d"
            perspective="1000px"
            ml="4"
          >
            <Text
              fontSize="9xl"
              fontWeight="bold"
              color="#FF6969"
              position="absolute"
              top="50%"
              transform="translateY(-50%) translateZ(40px)"
            >
              {rolling ? Math.floor(Math.random() * 10) : randomNumber2}
            </Text>
            {rolling && (
              <Box
                position="absolute"
                width="100%"
                height="100%"
                bg="linear-gradient(to right, #181818, #4b4b4b)"
                animation={`spin${randomNumber1} 3s cubic-bezier(0.5, 0, 0.5, 1) infinite`}
                zIndex="-1"
              />
            )}
          </Box>
        </Flex>
        <Center mb={8}>
    {rolling && (
      <Text sx={timerStyles}>
        {timer}
      </Text>
    )}
  </Center>
        <Center mb={8}>
          <div className="volume-icon" onClick={toggleMute}>
            {muted ? <Icon as={FaVolumeMute} boxSize={6} color="black" /> : <Icon as={FaVolumeUp} boxSize={6} color="black" />}
          </div>
        </Center>
        <Flex justifyContent="center" alignItems="center" mb="4" flexDirection={['column', 'row']} position="relative">
          {/* Input for numbers */}
          <Input
            type="text"
            value={selectedNumber}
            onChange={handleNumberChange}
            placeholder="Enter Number (00-99)"
            border="3px solid black"
            color="black"
            fontSize="20px"
            fontWeight="bold"
            _placeholder={{ color: 'black' }} // Change placeholder color to black
            _focus={{ borderColor: 'black' }} // Change border color on focus to black
            _hover={{ borderColor: 'black.500' }} // Change border color on hover
            style={{ padding: '8px', marginBottom: '10px', minWidth: '150px' }}
            borderRadius={'40px'}
          />
          {suggestions.length > 0 && (
            <div
              id="suggestion-box"
              style={{
                position: 'absolute',
                top: 'calc(100%)',
                left: '0',
                zIndex: '1',
                width: '100%',
                maxWidth: '410px', // Adjust the maximum width as needed
              }}
            >
              <Flex flexDirection="column" bg="white" borderRadius="md" boxShadow="md">
                {suggestions.map((suggestion, index) => (
                  <Text
                    key={index}
                    p="2"
                    borderBottom="1px solid #ccc"
                    cursor="pointer"
                    onClick={() => handleSuggestionClick(suggestion)}
                    _hover={{ bg: '#f0f0f0' }}
                    transition="background-color 0.3s"
                    width="100%"
                  >
                    {suggestion}
                  </Text>
                ))}
              </Flex>
            </div>
          )}
          {/* Input for bet amount */}
          <Input
            type="text" // Changed type to text for custom validation
            value={betAmount}
            onChange={handleBetAmountChange}
            placeholder="Enter amount"
            border={'3px solid black'}
            color={'black'}
            fontSize={'20px'}
            fontWeight={'bold'}
            _placeholder={{ color: 'black' }} // Change placeholder color to black
            _focus={{ borderColor: 'black' }} // Change border color on focus to black
            _hover={{ borderColor: 'black.500' }} // Change border color on hover
            style={{ padding: '8px', marginBottom: '10px', minWidth: '150px' }}
            borderRadius={'40px'}
            marginLeft={1}
            onKeyPress={(e) => {
              // Allow only numbers and prevent other characters
              if (e.key < '0' || e.key > '9') {
                e.preventDefault();
              }
            }}
          />
          <Button
            onClick={handleAddBet}
            bgColor='#FF6859'
            ml={[0, '20px']}
            fontSize={['16px', '18px']}
            borderRadius='full'
            size={['md', 'lg']}
            marginTop={['10px', 0]}
            leftIcon={<FaPlus />} // Use the leftIcon prop to add the icon
            _hover={{ bg: '#FF9EAA' }} // Change the hover color to red
          >
            ADD
          </Button>
        </Flex>
        <div style={{ overflowX: "auto" }}>
          {selectedBets.length > 0 && (
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                maxWidth: "100%",
                marginBottom: "1.5rem",
                boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                borderRadius: "0.375rem",
                backgroundColor: "#1a202c",
                color: "#cbd5e0",
              }}
            >
              <thead >
                <tr style={{ backgroundColor: "#2d3748" }}>
                  <th
                    style={{
                      color: "white",
                      fontSize: "0.875rem",
                      textTransform: "uppercase",
                      letterSpacing: "0.05em",
                      fontWeight: "bold",
                      textAlign: "center",
                      padding: "0.75rem",
                    }}
                  >
                    Number
                  </th>
                  <th
                    style={{
                      color: "white",
                      fontSize: "0.875rem",
                      textTransform: "uppercase",
                      letterSpacing: "0.05em",
                      fontWeight: "bold",
                      textAlign: "center",
                      padding: "0.75rem",
                    }}
                  >
                    Amount
                  </th>
                  <th style={{ padding: "0.75rem" }}></th>
                </tr>
              </thead>
              <tbody>
                {selectedBets.map((bet, index) => (
                  <tr
                    key={index}
                    style={{ backgroundColor: index % 2 === 0 ? "#2d3748" : "#4a5568" }}
                  >
                    <td
                      style={{
                        color: "#cbd5e0",
                        fontWeight: "medium",
                        textAlign: "center",
                        padding: "0.75rem",
                      }}
                    >
                      {bet.number < 10 ? `0${bet.number}` : bet.number}
                    </td>
                    <td
                      style={{
                        color: "#cbd5e0",
                        fontWeight: "medium",
                        textAlign: "center",
                        padding: "0.75rem",
                      }}
                    >
                      {bet.amount}₹
                    </td>
                    <td style={{ textAlign: "center", padding: "0.75rem" }}>
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDeleteBet(index)}
                      >
                        <DeleteIcon style={{ color: "#f56565" }} />
                      </button>
                    </td>
                  </tr>
                ))}
                <tr style={{ backgroundColor: "#2d3748" }}>
                  <td
                    style={{
                      color: "white",
                      fontSize: "0.875rem",
                      textTransform: "uppercase",
                      letterSpacing: "0.05em",
                      fontWeight: "bold",
                      textAlign: "center",
                      padding: "0.75rem",
                    }}
                  >
                    Total
                  </td>
                  <td
                    style={{
                      color: "white",
                      fontSize: "0.875rem",
                      textTransform: "uppercase",
                      letterSpacing: "0.05em",
                      fontWeight: "bold",
                      textAlign: "center",
                      padding: "0.75rem",
                    }}
                  >
                    {selectedBets.reduce((acc, bet) => acc + bet.amount, 0)}₹
                  </td>
                  <td style={{ padding: "0.75rem" }}></td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <Flex justifyContent="center" mt="4">
          <Button
            onClick={placeBetConfirmation}
            bgColor={'black'}
            borderRadius="full"
            px="14"
            py="8"
            fontSize="xl"
            color='#FFF5E1'
            fontWeight={'bold'}
            leftIcon={<FaGamepad />}
            boxShadow="dark-lg"
            _hover={{ backgroundColor: "#181818", transform: 'scale(1.05)', boxShadow: '2xl' }}
            isDisabled={selectedBets.reduce((total, bet) => total + bet.amount, 0) === 0 || rolling}
            marginBottom={'30px'}
          >
            Place Bet
          </Button>
        </Flex>
        {/* Game rules */}
        <Box bg="#FFB1B1" color="black" p="4" borderRadius="md" mb="4">
          <Heading mb="2" fontSize="xl" fontWeight="bold">
            Game Rules:
          </Heading>
          <Text fontSize="md">1. Place bets on numbers ranging from 00 to 99 with varying amounts.</Text>
          <Text fontSize="md">2. You can place multiple bets simultaneously.</Text>
          <Text fontSize="md">3. Enter your bet number and amount, click "Add" to include it in the table, and then click "Place Bet" when ready.</Text>
          <Text fontSize="md">4. The slot machine will start and stop automatically.</Text>
          <Text fontSize="md">5. You win if the slot machine displays a number you have bet on.</Text>
          <Text fontSize="md">6. Payouts are based on your bet amount; for instance, a bet of 10 could win you 900.</Text>
          <Text fontSize="md">7. Bets cannot be Changed once the slot machine has started.</Text>
          <Text fontSize="md">8. The game is fair, as it uses a random number generator to ensure fair game for everyone</Text>
          <Box
            p={4}
            borderRadius={'md'}
            mt={6}
          >
            <Heading
              as="h3"
              size={['sm', 'md']}
              textAlign="center"
              color={'#FF4D4D'}
            >
              Caution Note
            </Heading>
            <Text
              fontSize={['sm', 'md']}
              color={'#FF4D4D'}
              mt={4}
            >
              <b>Important:</b> Avoid navigating away or refreshing this page while placing bets to prevent errors or unregistered bets. We are not liable for issues caused by page refreshes or navigation; finalize all bets before leaving
            </Text>
          </Box>

        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
        <ModalOverlay />
        <ModalContent borderRadius="xl" boxShadow="dark-lg" bg="gray.800">
          <ModalHeader textAlign="center" fontSize={{ base: "lg", md: "2xl" }} fontWeight="bold" color="yellow.400">
            Confirm Bet
          </ModalHeader>
          <ModalCloseButton color="yellow.400" />
          <ModalBody>
            <Heading size="md" mb={{ base: 2, md: 4 }} textAlign="center" color="gray.200">
              Bet Details
            </Heading>

            <Box mb="2">
              <Text fontSize={{ base: "sm", md: "md" }} color="gray.300">
                <strong>Selected Numbers:</strong>
                <Text as="span" ml="2" color="blue.400" fontWeight="bold" p="1" borderRadius="md">
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {selectedBets.map((bet, index) => (
                      <li key={index}>Number: {bet.number} - Amount: ₹{bet.amount}</li>
                    ))}
                  </ul>
                </Text>
              </Text>
            </Box>
            <Box mb="2">
              <Text fontSize={{ base: "sm", md: "md" }} color="gray.300">
                <strong>Total Amount:</strong>
                <Text as="span" ml="2" color="red.400" bg="red.900" fontWeight="bold" border="1px" borderColor="red.400" p="1" borderRadius="md">
                  ₹{selectedBets.reduce((total, bet) => total + bet.amount, 0)}
                </Text>
              </Text>
            </Box>
            <Box mb="2">
              <Text fontSize={{ base: "sm", md: "md" }} color="gray.300">
                <strong>Wallet Balance After Deduction:</strong>
                <Text as="span" ml="2" color="purple.400" bg="purple.900" fontWeight="bold" border="1px" borderColor="purple.400" p="1" borderRadius="md">
                  ₹{walletBalance - selectedBets.reduce((total, bet) => total + bet.amount, 0)}
                </Text>
              </Text>
            </Box>

          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              colorScheme="yellow"
              mr={3}
              onClick={handleBet}
              size={{ base: "md", md: "lg" }}
              borderRadius="full"
              boxShadow="lg"
              bg="yellow.600"
              _hover={{ bg: "yellow.500" }}
            >
              Yes, Roll!
            </Button>
            <Button
              onClick={onClose}
              size={{ base: "md", md: "lg" }}
              borderRadius="full"
              boxShadow="lg"
              color="gray.300"
              bg="gray.700"
              _hover={{ bg: "gray.600" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>

  );
};

export default JodiNumberGame;


