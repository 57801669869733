import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  Flex,
  Grid,
  GridItem,
  useColorModeValue,
} from '@chakra-ui/react';

const HowToPlay = () => {
  const cardBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Box
      p={6}
      borderWidth="1px"
      border={'none'}
      bgColor={cardBgColor}
      mx={['2', '4', '6', '8']} // Adjust margins for different screen sizes
    >
      <VStack spacing={6}>
        <Heading
          as="h2"
          size={['sm', 'lg', 'md', 'lg']}
          textAlign="center"
          color="#8E7AB5"

          borderRadius={'full'}
          p={2}
        >
          Welcome to Our Gaming Platform
        </Heading>
        <Text
          fontSize={['sm', 'md', 'lg', 'xl']}
          bg={'#FEFAF6'}
          p={6}
          borderRadius={'3xl'}
          color={'grey'}
        >
          Welcome to Velodice, your ultimate gaming destination! Dive into an exhilarating world of entertainment with a diverse selection of games, including our thrilling spinner, dice slot, color game, and many more. Place your bets, test your luck, and experience the excitement of winning big based on game outcomes. Join us at Velodice for an unforgettable gaming adventure and let the fun begin!
        </Text>
      </VStack>
      <Flex direction={['column', 'row']} justify={['center', 'space-between']}>
        <VStack spacing={6} mt={6} mb={10}>
          <Heading
            as="h2"
            size={['sm', 'lg', 'md', 'lg']}
            textAlign="center"
            color="#8E7AB5"
            p={2}
            borderRadius={'full'}
          >
            How to Sign Up?
          </Heading>
          <List
            styleType="disc"
            fontSize={['sm', 'md', 'lg', 'xl']}
            bg={'#FEFAF6'}
            p={6}
            borderRadius={'3xl'}
            color={'grey'}
          >
            <ListItem>Click the "Sign Up" button on the navbar.</ListItem>
            <ListItem>Fill in your details such as username, mobile Number, and password.</ListItem>
            <ListItem>Ensure all checkboxes are selected during signup and provide a valid mobile number.</ListItem>
            <ListItem>Ensure you enter the correct number, as it's used for password recovery.</ListItem>
            <ListItem>Agree to the terms and conditions by checking the box.</ListItem>
            <ListItem>Click "Submit" to complete your registration.</ListItem>
            <ListItem>Log in with your new credentials to start playing!</ListItem>
          </List>
        </VStack>
        <VStack spacing={6} mt={6} mb={10}>
          <Heading
            as="h2"
            size={['sm', 'lg', 'md', 'lg']}
            textAlign="center"
            color="#8E7AB5"
            p={2}
            borderRadius={'full'}
          >
            How to Log In?
          </Heading>
          <List
            styleType="disc"
            fontSize={['sm', 'md', 'lg', 'xl']}
            bg={'#FEFAF6'}
            p={6}
            borderRadius={'3xl'}
            color={'grey'}
          >
            <ListItem>Click the "Log In" button on the homepage.</ListItem>
            <ListItem>Enter your registered username and password.</ListItem>
            <ListItem>Click "Log In" to access your account.</ListItem>
            <ListItem>If you forgot your password, click the "Forgot Password?" link.</ListItem>
            <ListItem>Follow the instructions to reset your password and regain access.</ListItem>
          </List>
        </VStack>
        <VStack spacing={6} mt={6} mb={10}>
          <Heading
            as="h2"
            size={['sm', 'lg', 'md', 'lg']}
            textAlign="center"
            color="#8E7AB5"
            p={2}
            borderRadius={'full'}
          >
            Forgot Password?
          </Heading>
          <List
            styleType="disc"
            fontSize={['sm', 'md', 'lg', 'xl']}
            bg={'#FEFAF6'}
            p={6}
            borderRadius={'3xl'}
            color={'grey'}
          >
            <ListItem>Click on the "Forgot Password?" link on the login page.</ListItem>
            <ListItem>Enter your registered username and mobile number.</ListItem>
            <ListItem>Click the "Verify Identity" button. If the details are verified, you will be prompted to enter a new password.</ListItem>
            <ListItem>Log in using your new password.</ListItem>
            <ListItem>In case of repeated issues, contact support for further assistance.</ListItem>

          </List>
        </VStack>
      </Flex>
      <Flex direction={['column', 'row']} justify={['center', 'space-between']}>

        <VStack spacing={6} mt={6} mb={10} flex={['1', '1', '1', '2']}>
          <Heading
            as="h2"
            size={['sm', 'lg', 'md', 'lg']}
            textAlign="center"
            color="#8E7AB5"

            p={2}
            borderRadius={'full'}
          >
            How to Play Spin & Win?
          </Heading>
          <List
            styleType="disc"
            fontSize={['sm', 'md', 'lg', 'xl']}
            bg={'#FEFAF6'}
            p={6}
            borderRadius={'3xl'}
            color={'grey'}
          >
            <ListItem> Bet on numbers 0 to 9 with different amounts.</ListItem>
            <ListItem>Wheel will atart automatic; and it stops after 10 seconds.</ListItem>
            <ListItem> You win if the wheel lands on your chosen number.</ListItem>
            <ListItem> Payouts depend on your bet amount.</ListItem>
            <ListItem> Bets can't be changed once the wheel spins.</ListItem>
            <ListItem> The game is fair with a random number generator.</ListItem>
          </List>
        </VStack>

        <VStack spacing={6} mt={6} ml={[0, 0, 0, 8]} mb={10} flex={['1', '1', '1', '2']}>
          <Heading
            as="h2"
            size={['sm', 'lg', 'md', 'lg']}
            textAlign="center"
            color="#8E7AB5"

            p={2}
            borderRadius={'full'}
          >
            How to Play Duo Digits?
          </Heading>
          <List
            styleType="round"
            fontSize={['sm', 'md', 'lg', 'xl']}
            bg={'#FEFAF6'}
            p={6}
            borderRadius={'3xl'}
            color={'grey'}
          >
            <ListItem>Bet on numbers from 00 to 99 with different amounts.</ListItem>
            <ListItem>Place multiple bets at the same time.</ListItem>
            <ListItem>The slot machine will start and stop automatically.</ListItem>
            <ListItem>You win if the slot machine shows a number you've bet on.</ListItem>
            <ListItem>Payouts are based on your bet amount; for example, betting 10 can win you 900.</ListItem>
            <ListItem>Bets can’t be changed once the slot machine starts.</ListItem>
            <ListItem>The game is fair with a random number generator.</ListItem>
          </List>
        </VStack>
      </Flex>
      <Flex direction={['column', 'row']} justify={['center', 'space-between']}>
        <VStack spacing={6} mt={6} mb={10} flex={['1', '1', '1', '2']}>
          <Heading
            as="h2"
            size={['sm', 'lg', 'md', 'lg']}
            textAlign="center"
            color="#8E7AB5"

            p={2}
            borderRadius={'full'}
          >
            How to Play Colour Shuffle?
          </Heading>
          <List
            styleType="disc"
            fontSize={['sm', 'md', 'lg', 'xl']}
            bg={'#FEFAF6'}
            p={6}
            borderRadius={'3xl'}
            color={'grey'}
          >
            <ListItem>Bet on only one of the 7 available colors with a single amount.</ListItem>
            <ListItem>The color shuffle will start and stop automatically.</ListItem>
            <ListItem>You win if the shuffled color matches your chosen color.</ListItem>
            <ListItem>Payouts are based on your bet amount.</ListItem>
            <ListItem>Bets can’t be changed once the color shuffle starts.</ListItem>
            <ListItem>Enjoy fair play with an automatic and random color shuffle.</ListItem>
          </List>
        </VStack>

        <VStack spacing={6} mt={6} ml={[0, 0, 0, 8]} mb={10} flex={['1', '1', '1', '2']}>
          <Heading
            as="h2"
            size={['sm', 'lg', 'md', 'lg']}
            textAlign="center"
            color="#8E7AB5"

            p={2}
            borderRadius={'full'}
          >
            How to Play Duo Dice?
          </Heading>
          <List
            styleType="round"
            fontSize={['sm', 'md', 'lg', 'xl']}
            bg={'#FEFAF6'}
            p={6}
            borderRadius={'3xl'}
            color={'grey'}
          >
            <ListItem>Bet on possible sums of two dice rolls: 2 to 12.</ListItem>
            <ListItem>You can place multiple bets on different sums with the same amount.</ListItem>
            <ListItem>The dice will roll automatically and the rolling will stop automatically.</ListItem>
            <ListItem>You win if the sum of the two dice matches one of your chosen sums.</ListItem>
            <ListItem>Payouts are based on your bet amount and the winning sum.</ListItem>
            <ListItem>Bets can’t be changed once the dice start rolling.</ListItem>
            <ListItem>The game is fair with a random number generator.</ListItem>
          </List>
        </VStack>
      </Flex>
      <Flex direction={['column', 'row']} justify={['center', 'space-between']}>
        <VStack spacing={6} mt={6} mb={10} flex={['1', '1', '1', '2']}>
          <Heading
            as="h2"
            size={['sm', 'lg', 'md', 'lg']}
            textAlign="center"
            color="#8E7AB5"

            p={2}
            borderRadius={'full'}
          >
            How to Play HighLow?
          </Heading>
          <List
            styleType="disc"
            fontSize={['sm', 'md', 'lg', 'xl']}
            bg={'#FEFAF6'}
            p={6}
            borderRadius={'3xl'}
            color={'grey'}
          >
            <ListItem>Select your bet: "Bet High" (sums 8 to 12) or "Bet Low" (sums 2 to 6).</ListItem>
            <ListItem>If you bet on "High," you win if the dice sum is between 8 and 12.</ListItem>
            <ListItem>If you bet on "Low," you win if the dice sum is between 2 and 6.</ListItem>
            <ListItem>A sum of 7 is a neutral outcome, resulting in a loss regardless of your bet.</ListItem>
            <ListItem>The dice roll automatically and will stop automatically after a short period.</ListItem>
            <ListItem>Each bet is placed once per roll, and bets cannot be changed once the dice start rolling.</ListItem>
            <ListItem>Winning and losing outcomes are determined by the dice sum in relation to your bet choice.</ListItem>
          </List>
        </VStack>
      </Flex>
      <VStack spacing={6} mt={6} mb={10}>
        <Heading
          as="h2"
          size={['sm', 'lg', 'md', 'lg']}
          textAlign="center"
          color="#8E7AB5"
          p={2}
          borderRadius={'full'}
        >
          How Do You Win?
        </Heading>
        <Text
          fontSize={['sm', 'md', 'lg', 'xl']}
          bg={'#FEFAF6'}
          p={6}
          borderRadius={'3xl'}
          color={'grey'}
        >
          Winning is all about correctly guessing the result of each game.
          Each game has specific rules and payout rates. The more difficult
          the prediction, the higher the payout. Make sure to check the
          payout rates before betting to understand your potential rewards.
        </Text>
      </VStack>

      <VStack spacing={6} mt={6} mb={10} align="stretch">
        <Heading
          as="h2"
          size={['sm', 'lg', 'md', 'lg']}
          textAlign="center"
          color="#8E7AB5"
          p={2}
          borderRadius={'full'}
        >
          Game Rates
        </Heading>
        <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={6}>
          <GridItem
            bg="#F9F9F9"
            p={4}
            borderRadius="md"
            boxShadow="md"
            textAlign="center"
          >
            <Text fontWeight="bold" color="#2A9D8F">Spin & Win</Text>
            <Text>Bet ₹10</Text>
            <Text color="#E76F51" fontWeight="bold">Win ₹90</Text>
          </GridItem>
          <GridItem
            bg="#F9F9F9"
            p={4}
            borderRadius="md"
            boxShadow="md"
            textAlign="center"
          >
            <Text fontWeight="bold" color="#2A9D8F">Duo Digits</Text>
            <Text>Bet ₹10</Text>
            <Text color="#E76F51" fontWeight="bold">Win ₹900</Text>
          </GridItem>
          <GridItem
            bg="#F9F9F9"
            p={4}
            borderRadius="md"
            boxShadow="md"
            textAlign="center"
          >
            <Text fontWeight="bold" color="#2A9D8F">Colour Shuffle</Text>
            <Text>Bet ₹10</Text>
            <Text color="#E76F51" fontWeight="bold">Win ₹20</Text>
          </GridItem>
          <GridItem
            bg="#F9F9F9"
            p={4}
            borderRadius="md"
            boxShadow="md"
            textAlign="center"
          >
            <Text fontWeight="bold" color="#2A9D8F">Duo Dice</Text>
            <Text>Bet ₹10</Text>
            <Text color="#E76F51" fontWeight="bold">Win ₹90</Text>
          </GridItem>
          <GridItem
            bg="#F9F9F9"
            p={4}
            borderRadius="md"
            boxShadow="md"
            textAlign="center"
          >
            <Text fontWeight="bold" color="#2A9D8F">HighLow</Text>
            <Text>Bet ₹10</Text>
            <Text color="#E76F51" fontWeight="bold">Win ₹20</Text>
          </GridItem>
        </Grid>
      </VStack>

      <VStack spacing={6} mt={6} mb={10}>
        <Heading
          as="h2"
          size={['sm', 'lg', 'md', 'lg']}
          textAlign="center"
          color="#8E7AB5"
          p={2}
          borderRadius={'full'}
        >
          Conclusion
        </Heading>
        <Text
          fontSize={['sm', 'md', 'lg', 'xl']}
          bg={'#FEFAF6'}
          p={6}
          borderRadius={'3xl'}
          color={'grey'}
        >
          Dive into the excitement of our wide range of gaming options! Our platform offers endless fun and winning opportunities, including:

          <List spacing={3} mt={3} mb={5} pl={5} color="grey">
            <ListItem>Spinner: Experience the thrill of chance with every spin.</ListItem>
            <ListItem>Duo Dice: Strategically play and make your moves count.</ListItem>
            <ListItem>Colour Shuffle: Enjoy the vibrant and dynamic action.</ListItem>
          </List>

          <Text mt={4}>
            <b>Play Responsibly:</b> While the stakes are high and the rewards are tempting, remember to approach gambling with caution:
          </Text>

          <List spacing={3} mt={3} mb={5} pl={5} color="grey">
            <ListItem>Set your limits and play within your means.</ListItem>
            <ListItem>Gambling is about having fun, not making money.</ListItem>
            <ListItem>Avoid chasing losses and prioritize enjoyment.</ListItem>
          </List>

          <Text>
            Our games are designed to thrill and entertain. Immerse yourself in the fun, keep your experience balanced, and enjoy every moment. Wishing you luck and lots of excitement—may your bets be sharp and your wins be thrilling!
          </Text>
        </Text>
        <Text
          fontSize={['sm', 'md', 'lg', 'xl']}
          fontWeight="bold"
          color="#2A9D8F"
          bgGradient="linear(to-r, black, #A8DADC)"
          bgClip="text"
          textAlign="center"
          p={4}
          borderRadius="md"
        >
          Simply put, the better your guess, the bigger your reward.
        </Text>

        <Box
          bg={'#FFE6E6'}
          p={4}
          borderRadius={'md'}
          border={'1px solid #FFCCCC'}
          mt={6}
        >
          <Heading
            as="h3"
            size={['sm', 'md']}
            textAlign="center"
            color="#FF6F6F"
          >
            Caution Note
          </Heading>
          <Text
            fontSize={['sm', 'md']}
            color={'#FF4D4D'}
            mt={4}
          >
            <b>Disclaimer:</b> Gambling can be addictive. Please ensure that you gamble responsibly. We encourage you to set limits on your time and money spent, and to seek help if you feel that gambling is negatively affecting your life. Our platform is intended for entertainment purposes only. We are not liable for any financial losses or psychological impacts that may arise from gambling activities. If you or someone you know needs assistance, please contact a professional support service.
          </Text>
        </Box>
      </VStack>


    </Box>
  );
};

export default HowToPlay;
