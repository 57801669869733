import React, { useState, useEffect } from 'react';
import '../Style/DiceGame.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FaDice } from 'react-icons/fa';
import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { jwtDecode } from 'jwt-decode';
import { Howl, Howler } from 'howler';
import diceRollSound from './dice-142528.mp3'; // Import your sound file
import useWalletBalance from './hook/useWalletBalance';
import useWalletActions from './hook/useWalletActions';

library.add(fas);

const DiceGame = () => {
  const [die1, setDie1] = useState('one');
  const [die2, setDie2] = useState('one');
  const [rolling, setRolling] = useState(false);
  const [betAmount, setBetAmount] = useState('');
  const [selectedCombinations, setSelectedCombinations] = useState([]);
  const [history, setHistory] = useState([]);
  const [confirmBet, setConfirmBet] = useState(false);
  const [timer, setTimer] = useState(10);
  const [username, setUsername] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const [winningNumber, setWinningNumber] = useState(null);
  const [soundMuted, setSoundMuted] = useState(false);
  const toast = useToast();
  const sides = ['one', 'two', 'three', 'four', 'five', 'six'];

  const { walletBalance, setWalletBalance, fetchWalletBalance } = useWalletBalance();
  const { withdrawFromWallet, depositToWallet } = useWalletActions();

  const sound = new Howl({
    src: [diceRollSound],
  });

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (rolling) {
        event.preventDefault();
        event.returnValue = 'You have an ongoing game. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [rolling]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const usernameFromToken = decodedToken.username;
      setUsername(usernameFromToken);
  
      // Trigger initial fetch and polling via the hook
      fetchWalletBalance(usernameFromToken);
    }
    // eslint-disable-next-line no-unused-vars
  }, [fetchWalletBalance]);
  
  useEffect(() => {
    Howler.volume(soundMuted ? 0 : 1);
    return () => {
      sound.unload();
    };
  }, [soundMuted]);

  const rollDice = () => {
    setRolling(true);
    setResultMessage('');
    setWinningNumber(null);
    let count = 10;
  
    const updateDice = () => {
      const randomDie1 = sides[Math.floor(Math.random() * sides.length)];
      const randomDie2 = sides[Math.floor(Math.random() * sides.length)];
      setDie1(randomDie1);
      setDie2(randomDie2);
    };
  
    const timerInterval = setInterval(() => {
      setTimer(count);
      count--;
  
      if (count < 0) {
        clearInterval(timerInterval);
  
        // Final dice roll after the timer ends
        const randomDie1 = sides[Math.floor(Math.random() * sides.length)];
        const randomDie2 = sides[Math.floor(Math.random() * sides.length)];
        setDie1(randomDie1);
        setDie2(randomDie2);
  
        const total = sides.indexOf(randomDie1) + sides.indexOf(randomDie2) + 2;
        const isWin = selectedCombinations.includes(total);
        const winningAmount = isWin ? parseInt(betAmount) * 9 : 0;
  
        setWinningNumber(total);
        sound.play();
        setResultMessage(isWin ? `You won ₹${winningAmount}!` : 'You lost!');
  
        addToHistory(isWin, winningAmount);
        updateBackendBetResult(isWin, winningAmount);
  
        setRolling(false);
  
        toast({
          title: isWin ? 'Congratulations!' : 'Better luck next time!',
          description: isWin ? `You won ₹${winningAmount}!` : 'You lost!',
          status: isWin ? 'success' : 'error',
          duration: 2000,
          isClosable: true,
          position: 'bottom',
        });
  
      } else {
        updateDice(); // Update dice on every interval
      }
    }, 1000);
  
    // Initial dice roll
    updateDice();
  };
  

  const handleBet = () => {
    if (betAmount !== '' && parseInt(betAmount) > 0 && selectedCombinations.length > 0) {
      const totalBetAmount = parseInt(betAmount) * selectedCombinations.length;
      if (totalBetAmount <= walletBalance) {
        setConfirmBet(true);
      } else {
        toast({
          title: 'Insufficient balance.',
          description: 'Please deposit funds into your wallet.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: 'Invalid Bet.',
        description: 'Please enter a valid bet amount and select at least one combination.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleConfirmBet = async () => {
    const totalBetAmount = parseInt(betAmount) * selectedCombinations.length;
    await withdrawFromWallet(totalBetAmount, true); // Deduct the amount immediately and mark as bet related

    rollDice(); // Start rolling the dice

    setConfirmBet(false);
  };

  const handleCloseModal = () => {
    setConfirmBet(false);
  };

  const handleBetAmountChange = (e) => {
    setBetAmount(e.target.value);
  };

  const addToHistory = (win, amount) => {
    const newHistory = [...history, { win, amount: win ? amount : -betAmount * selectedCombinations.length }];
    setHistory(newHistory);
  };

  const selectCombination = (combination) => {
    const updatedCombinations = [...selectedCombinations];
    if (updatedCombinations.includes(combination)) {
      updatedCombinations.splice(updatedCombinations.indexOf(combination), 1);
    } else {
      updatedCombinations.push(combination);
    }
    setSelectedCombinations(updatedCombinations);
  };

  const toggleSound = () => {
    setSoundMuted(!soundMuted);
  };

  const updateBackendBetResult = async (isWin, winningAmount) => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const totalBetAmount = parseInt(betAmount) * selectedCombinations.length;

        const response = await fetch('https://newproject3-0-hem2.onrender.com/account/bet', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            username,
            betAmount: totalBetAmount, // Send total bet amount instead of individual betAmount
            gameName: 'Duo Dice',
            category: 'Dice',
            betResult: isWin ? 'Win' : 'Loss',
            selectedNumbers: selectedCombinations,
            winningAmount,
          }),
        });

        if (response.ok) {
          if (isWin) {
            await depositToWallet(winningAmount, true);
          }
        } else {
          const data = await response.json();
          toast({
            title: 'Error',
            description: data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error placing bet:', error.message);
      }
    }
  };

  return (
    <Flex justifyContent="center" alignItems="center" minHeight="100vh" backgroundColor="#151515" color="#FFF">
      <Box p="4" bg="transparent" w="90%" maxW="800px" borderRadius="md">
        <Flex alignItems="center" justifyContent="center">
          <FaDice style={{ color: '#EEEEEE', fontSize: '2rem', marginRight: '1rem' }} />
          <Heading as="h1" mb="4" textAlign="center" color="#A91D3A" fontSize={{ base: '3xl', md: '6xl' }} fontWeight="bold">
            Duo Dice
          </Heading>
          <FaDice style={{ color: '#EEEEEE', fontSize: '2rem', marginLeft: '1rem' }} />
        </Flex>
        <Box textAlign="center" mb="4">
          <Flex justifyContent="center" alignItems="center" mb="4">
            <Box className={`Die ${rolling && 'Die-shaking'}`}>
              <FontAwesomeIcon icon={['fas', `fa-dice-${die1}`]} size='lg' />
            </Box>
            <Box className={`Die ${rolling && 'Die-shaking'}`}>
              <FontAwesomeIcon icon={['fas', `fa-dice-${die2}`]} size="lg" />
            </Box>
          </Flex>
          <Box textAlign="center" mt={'-30px'} mb="4">
            <Button onClick={toggleSound} size={'md'} color={'#C73659'} colorScheme="cyan" variant={'ghost'} leftIcon={soundMuted ? <FontAwesomeIcon icon={['fas', 'volume-mute']} /> : <FontAwesomeIcon icon={['fas', 'volume-up']} />}>
            </Button>
          </Box>
          {rolling && <Text color={'green.500'} fontWeight={'bold'} fontSize="4xl">{timer}sec</Text>}
        </Box>
        {winningNumber !== null && (
          <Text fontSize="6xl" color={'orange.400'} fontWeight="bold" textAlign="center" mb="4">
            {winningNumber}
          </Text>
        )}
        <Flex
          direction={{ base: 'column', md: 'row' }} // Stack items vertically on small screens, horizontally on medium and larger screens
          justifyContent="center"
          alignItems="center"
          mb="4"
          p={{ base: '4', md: '8' }} // Add padding for better spacing on small screens
          gap={{ base: '2', md: '4' }} // Space between items
        >
          <Input
            type="number"
            value={betAmount}
            onChange={handleBetAmountChange}
            placeholder="Enter amount"
            borderColor="#C73659"
            borderRadius="40px"
            style={{ padding: '8px', color: 'white', minWidth: '80%', maxWidth: '300px' }} // Ensure the input takes full width on small screens but limits its maximum width
          />
          <Button
            mt={{ base: '2', md: '0' }} // Add top margin on small screens for spacing
            ml={{ base: '0', md: '2' }} // Remove left margin on small screens for stacking
            color="black"
            variant="solid"
            onClick={handleBet}
            borderRadius="40px"
            bg="#A91D3A"
            width={{ base: '100%', md: '250px', lg: '300px' }} // Increase width for larger screens
            fontWeight="bold"
            fontSize={{ base: '14px', md: '16px', lg: '18px' }} // Adjust font size for different screens
            _hover={{ bg: "#C73659" }}
            border="2px solid black"
            isDisabled={!betAmount || selectedCombinations.length === 0 || rolling}
            whiteSpace="nowrap" // Prevent text from wrapping
            p={{ base: '8px', md: '12px', lg: '16px' }} // Increase padding for larger screens
          >
            {rolling ? 'Good Luck!' : 'Place Bet'}
          </Button>
        </Flex>
        <Flex justifyContent="center" flexWrap="wrap" mb="4">
          {Array.from({ length: 11 }, (_, i) => i + 2).map((total) => (
            <Box
              key={total}
              onClick={() => selectCombination(total)}
              m="1"
              p="3"
              color={'black'}
              fontWeight={'bold'}
              bg={selectedCombinations.includes(total) ? '#A91D3A' : '#EEEEEE'}
              borderRadius="md"
              cursor="pointer"
              textAlign="center"
              minWidth="40px"

            >
              <Text fontSize="lg">{total}</Text>
            </Box>
          ))}
        </Flex>
        <Box bg="#C73659" p="4" borderRadius="md" mb="4">
          <Heading mb="2" fontSize="lg" fontWeight="bold">
            History:
          </Heading>
          {history.map((item, index) => (
            <Flex key={index} justifyContent="space-between" alignItems="center">
              <Text fontSize="md">{item.win ? 'Won' : 'Lost'}</Text>
              <Text fontSize="md">{item.amount}₹</Text>
            </Flex>
          ))}
        </Box>
        <Box p="4" bg="#C73659" borderRadius="md" mb="4">
          <Heading mb="2" fontSize="lg" fontWeight="bold">
            Rules:
          </Heading>
          <VStack align="start" spacing="2">
            <Text>- Bet on possible sums of two dice rolls: 2 to 12.</Text>
            <Text>- You can place multiple bets on different sums with the same amount.</Text>
            <Text>- The dice will roll automatically and the rolling will stop automatically..</Text>
            <Text>- You win if the sum of the two dice matches one of your chosen sums.</Text>
            <Text>- Payouts are based on your bet amount and the winning sum.</Text>
            <Text>- Bets can’t be changed once the dice start rolling.</Text>
            <Text>- The game is fair, as it uses a random number generator to ensure fair game for everyone</Text>
            <Box
          
            p={4}
            borderRadius={'md'}
            border={'1px solid #FFCCCC'}
            mt={6}
          >
            <Heading
              as="h3"
              size={['sm', 'md']}
              textAlign="center"
              color="#FFCCCC"
            >
              Caution Note
            </Heading>
            <Text
              fontSize={['sm', 'md']}
              color={'#FG0D4D'}
              mt={4}
            >
              <b>Important:</b> Avoid navigating away or refreshing this page while placing bets to prevent errors or unregistered bets. We are not liable for issues caused by page refreshes or navigation; finalize all bets before leaving
              </Text>
          </Box>
          </VStack>
        </Box>
        <Modal isOpen={confirmBet} onClose={handleCloseModal} size={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
          <ModalOverlay />
          <ModalContent borderRadius="xl" boxShadow="dark-lg" bg="gray.800">
            <ModalHeader textAlign="center" fontSize={{ base: "lg", md: "2xl" }} fontWeight="bold" color="yellow.400">Confirm Bet</ModalHeader>
            <ModalCloseButton color="yellow.400" />
            <ModalBody>
              <Heading size="md" mb={{ base: 2, md: 4 }} textAlign="center" color="gray.200">Bet Details</Heading>
              <Box mb="2">
                <Text fontSize={{ base: "sm", md: "md" }} color="gray.300">
                  <strong>Bet Amount:</strong>
                  <Text as="span" ml="2" color="green.400" bg="green.900" fontWeight="bold" border="1px" borderColor="green.400" p="1" borderRadius="md">
                    ₹{betAmount}
                  </Text>
                </Text>
              </Box>
              <Box mb="2">
                <Text fontSize={{ base: "sm", md: "md" }} color="gray.300">
                  <strong>Selected Numbers:</strong>
                  <Text as="span" ml="2" color="blue.400" bg="blue.900" fontWeight="bold" border="1px" borderColor="blue.400" p="1" borderRadius="md">
                    {selectedCombinations.join(', ')}
                  </Text>
                </Text>
              </Box>
              <Box mb="2">
                <Text fontSize={{ base: "sm", md: "md" }} color="gray.300">
                  <strong>Total Amount:</strong>
                  <Text as="span" ml="2" color="red.400" bg="red.900" fontWeight="bold" border="1px" borderColor="red.400" p="1" borderRadius="md">
                    ₹{betAmount * selectedCombinations.length}
                  </Text>
                </Text>
              </Box>
              <Box mb="2">
                <Text fontSize={{ base: "sm", md: "md" }} color="gray.300">
                  <strong>Wallet Balance After Deduction:</strong>
                  <Text as="span" ml="2" color="purple.400" bg="purple.900" fontWeight="bold" border="1px" borderColor="purple.400" p="1" borderRadius="md">
                    ₹{walletBalance - betAmount * selectedCombinations.length}
                  </Text>
                </Text>
              </Box>
              <Box>
                <Text fontSize={{ base: "sm", md: "md" }} color="gray.300">
                  <strong>Potential Winnings:</strong>
                  <Text as="span" ml="2" color="orange.400" bg="orange.900" fontWeight="bold" border="1px" borderColor="orange.400" p="1" borderRadius="md">
                    ₹{betAmount} * 9 = ₹{betAmount * 9}
                  </Text>
                </Text>
              </Box>
            </ModalBody>
            <ModalFooter justifyContent="center">
              <Button colorScheme="yellow" mr={3} onClick={handleConfirmBet} size={{ base: "md", md: "lg" }} borderRadius="full" boxShadow="lg" bg="yellow.600" _hover={{ bg: "yellow.500" }}>
                Yes, Roll!
              </Button>
              <Button onClick={handleCloseModal} size={{ base: "md", md: "lg" }} borderRadius="full" boxShadow="lg" color="gray.300" bg="gray.700" _hover={{ bg: "gray.600" }}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

      </Box>
    </Flex>
  );
};

export default DiceGame;

