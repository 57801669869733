import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  Center,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const ForgetPassword = () => {
  const [username, setUsername] = useState('');
  const [mobile, setMobile] = useState('');
  const [userId, setUserId] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const navigate = useNavigate();

  const handleVerifyIdentity = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://newproject3-0-hem2.onrender.com/api/verify-identity', { username, mobile });
      if (response.data.status === 'ok') {
        setUserId(response.data.userId);
        setStep(2);
        setError('');
      } else {
        setError('User not found with the provided username and mobile number.');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('An unexpected error occurred. Please try again later.');
      }
      setTimeout(() => {
        setError('');
      }, 2000); // Dismiss error message after 2 seconds
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://newproject3-0-hem2.onrender.com/api/reset-password', { userId, newPassword });
      if (response.data.status === 'ok') {
        setSuccessMsg('Your password has been reset successfully. You will be redirected to the login page shortly.');
        setTimeout(() => {
          navigate('/login'); // Redirect to login page after 3 seconds
        }, 3000);
      } else {
        setError('An error occurred while resetting the password. Please try again.');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('An unexpected error occurred. Please try again later.');
      }
      setSuccessMsg('');
    }
  };

  useEffect(() => {
    // Clear success message after 5 seconds
    const timer = setTimeout(() => {
      setSuccessMsg('');
    }, 5000);
    return () => clearTimeout(timer);
  }, [successMsg]);

  return (
    <Box
      maxW={{ base: "100%", sm: "400px" }}
      mx="auto"
      mt="8"
      p="6"
      height={'400px'}
      boxShadow="xl"
      marginBottom={'30px'}
      bg={'white'}
      _hover={{ transform: "scale(1.01)" }}
      transition="all 0.3s ease"
    >
      <Center>
        <Text fontSize="xl" mb="4" fontWeight={'bold'} color="#001F3F">
          Forgot Password
        </Text>
      </Center>
     
      {step === 1 && (
        <form onSubmit={handleVerifyIdentity}>
          <VStack spacing="4">
            <FormControl isRequired>
              <FormLabel color="#001F3F">Username</FormLabel>
              <Input
                type="text"
                placeholder="Username"
               color="#001F3F"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel color="#001F3F">Mobile Number</FormLabel>
              <Input
                type="number"
                placeholder="Mobile Number"
               color="#001F3F"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </FormControl>
            <Button type="submit"color="white" fontSize="18px" bg="#001F3F" w="100%">
              Verify Identity
            </Button>
          </VStack>
        </form>
      )}


       {error && (
        <Alert status="error" mt='4' mb="4">
          <AlertIcon />
          {error}
        </Alert>
      )}
      {successMsg && (
        <Alert status="success" mb="4">
          <AlertIcon />
          {successMsg}
        </Alert>
      )}
      {step === 2 && (
        <form onSubmit={handleResetPassword}>
          <VStack spacing="4">
            <FormControl isRequired>
              <FormLabel color="#001F3F">New Password</FormLabel>
              <Input
                type="password"
                placeholder="New Password"
               color="#001F3F"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </FormControl>
            <Button type="submit" color="white" fontSize="18px" bg="#001F3F" w="100%">
              Reset Password
            </Button>
          </VStack>
        </form>
      )}
    </Box>
  );
};

export default ForgetPassword;
