import React, { useState, useEffect } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function ScrollButton() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const buttonWidth = useBreakpointValue({
    base: '40px', // Adjust the width for mobile and small screens
    md: '45px', // Default width for medium and larger screens
  });

  const buttonSize = buttonWidth || '50px'; // Use the specified width or a default value

  return (
    <Box
      className={`scroll-button ${showButton ? 'show' : ''}`}
      onClick={scrollToTop}
      style={{
        position: 'fixed',
        bottom: showButton ? '20px' : '-60px',
        right: '10px',
        backgroundColor: 'black',
        color: 'rgba(226,107,189,1)',
        fontSize: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '50%',
        opacity: showButton ? 1 : 0,
        transition: 'opacity 0.3s, bottom 0.3s',
        width: buttonSize,
        height: buttonSize,
      }}
    >
      <span className="arrow-up">
        <FontAwesomeIcon icon={faArrowUp} bounce style={{ color: "rgba(226,107,189,1)" }} />
      </span>
    </Box>
  );
}

export default ScrollButton;
