import { Box, Heading, VStack, Image, Flex, Fade, Text, keyframes } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';

// Function to convert image to base64
const getBase64 = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

// Define keyframe animations
const pulsingAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const floatAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const WelcomeBanner = () => {
  const [inView, setInView] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [imageSrcs, setImageSrcs] = useState({ logo: '', spin: '' });

  const { ref, inView: view } = useInView({
    triggerOnce: true,
    rootMargin: '100px 0px',
    threshold: 0.5,
  });

  useEffect(() => {
    if (view) {
      setInView(true);
    }
  }, [view]);

  useEffect(() => {
    const loadImages = async () => {
      const logoUrl = '/images/logo-no-background.png';
      const spinUrl = '/images/spin.png';

      const logoCached = localStorage.getItem('logo');
      const spinCached = localStorage.getItem('spin');

      if (logoCached && spinCached) {
        setImageSrcs({ logo: logoCached, spin: spinCached });
        setImagesLoaded(true);
      } else {
        getBase64(logoUrl, (logoBase64) => {
          localStorage.setItem('logo', logoBase64);
          setImageSrcs((prev) => ({ ...prev, logo: logoBase64 }));
        });
        getBase64(spinUrl, (spinBase64) => {
          localStorage.setItem('spin', spinBase64);
          setImageSrcs((prev) => ({ ...prev, spin: spinBase64 }));
        });
        setImagesLoaded(true);
      }
    };

    loadImages();
  }, []);

  return (
    <Box
      bg="#040404"
      h={{ base: '85vh', md: '80vh', lg: '100vh' }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      color="white"
      position="relative"
      px={{ base: 4, md: 8 }}
      ref={ref}
    >
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify="space-between"
        width="100%"
        padding={4}
      >
        <VStack
          spacing={4}
          alignItems="flex-start"
          zIndex="1"
          maxWidth={{ base: '90%', md: '50%', lg: '50%' }}
          textAlign={{ base: 'center', md: 'left' }}
        >
          <Fade in={inView}>
            <Heading
              fontFamily="Hammersmith One, sans-serif"
              color="#B0E57C"
              fontSize={{ base: '2em', md: '3em', lg: '4.2em' }}
              animation={`${pulsingAnimation} 2s ease-in-out infinite, ${floatAnimation} 4s ease-in-out infinite`}
            >
              Join the Excitement of Gaming at{' '}
              {imagesLoaded && (
                <Image
                  src={imageSrcs.logo}
                  alt="velodice"
                  w={{ base: '6em', md: '7em', lg: '10em' }}
                  h="auto"
                  mt={10}
                  loading="lazy"
                  display="inline-block"
                  marginLeft="10px"
                />
              )}
            </Heading>
            <Text fontSize={{ base: '1em', md: '1.5em' }} color="#B0E57C">
              Experience thrilling games, fantastic wins, and endless fun!
            </Text>
          </Fade>
        </VStack>
        <Flex justify="space-around" wrap="wrap" mt={4} width="100%">
          {imagesLoaded && (
            <Image
              src={imageSrcs.spin}
              alt="Spin"
              w={{ base: '60%', md: '65%' }}
              h="auto"
              borderRadius="15px"
              animation={`${spinAnimation} 10s linear infinite`}
            />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default WelcomeBanner;
