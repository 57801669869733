import React, { createContext, useContext, useEffect, useState } from 'react';
import {jwtDecode} from 'jwt-decode';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        if (username) {
          // Initial balance fetch
          fetchWalletBalance(username);
        }
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
  }, []);

  const fetchWalletBalance = async (username) => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await fetch(`https://newproject3-0-hem2.onrender.com/account/${username}/balance`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setBalance(data.balance !== undefined ? data.balance : 0);
        } else {
          setBalance(0);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        setBalance(0);
      }
    }
  };

  return (
    <WebSocketContext.Provider value={{ balance, setBalance }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
