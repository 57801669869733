import React from 'react';
import {
  Box,
  Center,
  Heading,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';

const TermAndConditions = () => {
  return (
    <Box p={4}>
      <Center>
        <Heading as="h1" size="2xl" color="red.200" mb={8}>
          Terms and Conditions
        </Heading>
      </Center>
      <Box
        bg="#F7E7DC"
        p={6}
        borderRadius="md"
        boxShadow="md"
      >
        <SimpleGrid columns={[1, null, 1]} spacing={8}>
          <VStack alignItems="flex-start">
            <Text fontSize={['sm', 'md', 'lg']} textAlign="left">
              By using VeloDice, you agree to the following terms and conditions:
            </Text>
            <Text fontSize={['sm', 'md', 'lg']} textAlign="left">
              1. VeloDice promotes responsible gaming. While gaming can be enjoyable and entertaining, we urge our users to gamble responsibly and within their means.
            </Text>
            <Text fontSize={['sm', 'md', 'lg']} textAlign="left">
              2. VeloDice does not endorse or support any form of addiction to gaming or gambling. If you or someone you know is struggling with gambling addiction, please seek help from professional services.
            </Text>
            <Text fontSize={['sm', 'md', 'lg']} textAlign="left">
              3. VeloDice provides information and entertainment purposes only. We do not guarantee the accuracy or reliability of any information provided on our platform.
            </Text>
            <Text fontSize={['sm', 'md', 'lg']} textAlign="left">
              4. Users of VeloDice accept that gaming involves a risk of financial loss, and they are solely responsible for any such losses incurred.
            </Text>
            <Text fontSize={['sm', 'md', 'lg']} textAlign="left">
              5. VeloDice reserves the right to modify or terminate any service or feature provided without prior notice.
            </Text>
            <Text fontSize={['sm', 'md', 'lg']} textAlign="left">
              6. By using VeloDice, you acknowledge and agree to adhere to all terms and conditions stated herein.
            </Text>
            <Text fontSize={['sm', 'md', 'lg']} textAlign="left">
              7. Any content or materials accessed through VeloDice are for personal, non-commercial use only. Reproduction, redistribution, or commercial use of any content without explicit permission is prohibited.
            </Text>
            <Text fontSize={['sm', 'md', 'lg']} textAlign="left">
              8. VeloDice is not responsible for any third-party content or links provided on our platform. Use of such third-party sites is at your own risk.
            </Text>
            <Text fontSize={['sm', 'md', 'lg']} textAlign="left">
              9. We reserve the right to suspend or terminate accounts that violate our terms or engage in fraudulent or illegal activities.
            </Text>
            <Text fontSize={['sm', 'md', 'lg']} textAlign="left">
              10. If any part of these terms is found to be invalid or unenforceable, the remaining terms will continue to apply.
            </Text>
          </VStack>
        </SimpleGrid>
      </Box>

      <Spacer mt={8} />

      <Box
        bg="gray.100"
        p={6}
        borderRadius="md"
        boxShadow="md"
      >
        <VStack alignItems="flex-start">
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Disclaimer:
          </Text>
          <Text fontSize="md">
            The information provided on VeloDice is for informational and entertainment purposes only. While we strive to ensure the accuracy of the content, we cannot guarantee that it is error-free or complete. We are not responsible for any inaccuracies or omissions, and users should verify any information before relying on it. Use of the site is at your own risk, and we disclaim all liability for any damages or losses resulting from its use.
          </Text>
        </VStack>
        <Spacer mt={6} />
        <VStack alignItems="flex-start">
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Important Notice:
          </Text>
          <Text fontSize="md">
            Please be cautious and ensure you are visiting our official website and URL to avoid scams. Our official domain is <b>VeloDice.com</b>.
          </Text>
        </VStack>
      </Box>

      {/* Include your ContactCard component here or any other relevant sections */}
    </Box>
  );
};

export default TermAndConditions;
