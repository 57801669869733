import React from 'react';
import { Box, Heading, Text, Stack } from '@chakra-ui/react';

const AboutUs = () => {
  return (
    <Box p={4} mx="auto" maxW={['95%', '90%', '80%', '90%']}>
      <Box
        p={6}
        bgGradient="linear(to-r, #D8EFD3, #686D76)"
        boxShadow="md"
        borderRadius="md"
        mb={4}
      >
        <Heading as="h2" fontSize={['md', 'md', '2xl', '3xl']} mb={4} color="black">
          About Us
        </Heading>
        <Text fontSize={['sm', 'md', 'lg', 'xl']} color="black">
          VeloDice is a new and innovative online gaming platform, dedicated to providing exciting and reliable gaming experiences.
        </Text>
        <Text fontSize={['sm', 'md', 'lg', 'xl']} mt={4} color="black">
          Join us as we embark on this journey to redefine online gaming. Whether you're into spinners, dice slots, or color games, VeloDice promises excellence and fun every step of the way.
        </Text>
      </Box>

      <Box
        bgGradient="linear(to-r,  #D8EFD3, #686D76)"
        p={6}
        boxShadow="md"
        borderRadius="md"
      >
        <Heading as="h3" size="lg" mb={4} color="black">
          Our Vision
        </Heading>
        <Stack spacing={2} color="black">
          <Text fontSize={['sm', 'md', 'lg']} color="black">
            VeloDice aims to push the boundaries of online gaming, offering cutting-edge features and unparalleled experiences.
          </Text>
          <Text fontSize={['sm', 'md', 'lg']} color="black">
            We are committed to excellence in everything we do, ensuring the highest standards in game development, customer support, and community engagement.
          </Text>
          <Text fontSize={['sm', 'md', 'lg']} color="black">
            Your security and trust are our top priorities. VeloDice guarantees fair play and transparent operations.
          </Text>
          <Text fontSize={['sm', 'md', 'lg']} color="black">
            Join our growing community of gamers and be part of something special. Together, we'll create an exciting and inclusive gaming environment.
          </Text>
        </Stack>
      </Box>
    </Box>
  );
};

export default AboutUs;
