import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Text,
  Flex,
  Select,
  Radio,
  RadioGroup,
  Stack,
  Spinner,
  Heading,
  Icon,
  Tooltip,
  Button,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { jwtDecode } from 'jwt-decode';
import { MdErrorOutline } from 'react-icons/md';
import { FaTrophy, FaFrown } from 'react-icons/fa';

const BetHistory = () => {
  const [betData, setBetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('createdAt');
  const [filterBy, setFilterBy] = useState('');
  const [gameFilter, setGameFilter] = useState('');
  const [highlightedBet, setHighlightedBet] = useState(null);
  const [showDetails, setShowDetails] = useState(false); // State for showing full details

  useEffect(() => {
    const fetchBetData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await fetch(
          `https://newproject3-0-hem2.onrender.com/account/${username}/betting-history`
        );
        if (!response.ok) {
          throw new Error('No Bet History Found.');
        }
        const data = await response.json();
        setBetData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchBetData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const renderMessage = (outcome) => (
    <Text fontWeight="bold" color={outcome === 'Win' ? 'green.500' : 'red.500'}>
      {outcome === 'Win' ? 'Congratulations! You Won!' : 'Better luck next time!'}
    </Text>
  );

  const handleSortChange = (e) => setSortBy(e.target.value);

  const handleFilterChange = (value) => setFilterBy(value);

  const handleGameFilterChange = (value) => setGameFilter(value);

  const highlightBet = (index) => setHighlightedBet(index);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const renderSelectedNumbers = (selectedNumbers, gameName) => {
    if (!selectedNumbers || selectedNumbers.length === 0) return 'Not available';

    if (gameName === 'Colour Shuffle') {
      return selectedNumbers.join(', ');
    } else {
      let renderedNumbers = selectedNumbers
        .map((item) => {
          if (typeof item === 'object' && item.hasOwnProperty('number') && item.hasOwnProperty('betAmount')) {
            return `{${item.number}: ₹${item.betAmount}}`;
          } else if (typeof item === 'string' || typeof item === 'number') {
            return item.toString();
          } else {
            return 'Invalid format';
          }
        })
        .join(', ');

      // Truncate if showDetails is false
      if (!showDetails && renderedNumbers.length > 50) {
        renderedNumbers = `${renderedNumbers.slice(0, 50)}...`;
      }

      return renderedNumbers;
    }
  };

  const filteredData = betData
    .filter((item) => {
      if (filterBy === '') return true;
      if (filterBy === 'win' && item.betResult === 'Win') return true;
      if (filterBy === 'loss' && item.betResult === 'Loss') return true;
      return false;
    })
    .filter((item) => {
      if (gameFilter === '') return true;
      return item.gameName === gameFilter;
    });

  const sortedData = filteredData.sort((a, b) => {
    if (sortBy === 'createdAt') return new Date(b.createdAt) - new Date(a.createdAt);
    if (sortBy === 'gameName') return a.gameName.localeCompare(b.gameName);
    if (sortBy === 'betAmount') return b.betAmount - a.betAmount;
    return 0;
  });

  const gameOptions = [
    { label: 'All Games', value: '' },
    { label: 'Spin & Win', value: 'Spin & Win' },
    { label: 'Duo Digits', value: 'Duo Digits' },
    { label: 'Colour Shuffle', value: 'Colour Shuffle' },
    { label: 'Duo Dice', value: 'Duo Dice' },
    { label: 'High Low', value: 'High|Low' },
  ];
  const getBackgroundColor = (betType) => {
    switch (betType) {
      case 'Low':
        return 'yellow.100';
      case 'Neutral':
        return 'blue.100';
      case 'High':
        return '#FFD3B6';
      default:
        return 'gray.100'; // Default color if betType is unknown or empty
    }
  };
  return (
    <Box p={4}>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      ) : error ? (
        <Flex direction="column" align="center" justify="center" h="200px">
          <Box color="gray.500" textAlign="center" mb={4}>
            <Icon as={MdErrorOutline} boxSize={8} color="red.500" />
            <Heading as="h3" size="lg" mt={2}>
              {error}
            </Heading>
          </Box>
        </Flex>
      ) : betData.length === 0 ? (
        <Box textAlign="center" mt={10}>
          <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold" color="gray.500">
            You haven't placed any bets yet.
          </Text>
        </Box>
      ) : (
        <Box>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            mb={4}
            p={4}
            bg="white"
            borderRadius="lg"
            boxShadow="md"
          >
            <Flex alignItems="center" mb={{ base: 4, md: 0 }} fontSize={{ base: 'sm', md: 'md' }}>
              <Text fontWeight="bold" mr={2}>
                Sort By:
              </Text>
              <Select value={sortBy} onChange={handleSortChange} width="150px" fontSize="inherit">
                <option value="createdAt">Date</option>
                <option value="gameName">Game Name</option>
                <option value="betAmount">Betting Amount</option>
              </Select>
            </Flex>
            <Flex alignItems="center" mb={{ base: 4, md: 0 }} fontSize={{ base: 'sm', md: 'md' }}>
              <Text fontWeight="bold" mr={2}>
                Filter By:
              </Text>
              <RadioGroup value={filterBy} onChange={handleFilterChange}>
                <Stack direction="row" spacing={4}>
                  <Radio value="">All</Radio>
                  <Radio value="win">Wins</Radio>
                  <Radio value="loss">Losses</Radio>
                </Stack>
              </RadioGroup>
            </Flex>
            <Flex alignItems="center" fontSize={{ base: 'sm', md: 'md' }}>
              <Text fontWeight="bold" mr={2}>
                Filter by Game:
              </Text>
              <Select value={gameFilter} onChange={(e) => handleGameFilterChange(e.target.value)} width="150px" fontSize="inherit">
                {gameOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </Flex>
          </Flex>
          <Grid
            templateColumns={{ base: 'repeat(1, 100%)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
            gap={6}
          >
            {sortedData.map((item, index) => (
              <GridItem key={index}>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  onHoverEnd={() => highlightBet(null)}
                >
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    p={4}
                    bg={highlightedBet === index ? 'teal.50' : 'white'}
                    boxShadow="lg"
                    _hover={{ transform: 'translateY(-4px)', boxShadow: 'xl' }}
                    position="relative"
                    overflow="hidden"
                  >
                    <Box
                      position="absolute"
                      top="0"
                      right="0"
                      bg={item.betResult === 'Win' ? 'green.500' : 'red.500'}
                      color="white"
                      px={2}
                      py={1}
                      borderBottomLeftRadius="lg"
                      fontSize={{ base: 'sm', md: 'md' }}
                    >
                      {item.betResult}
                    </Box>
                    <Flex justifyContent="space-between" alignItems="center" mb={2}>
                      <Text
                        as="span"
                        borderBottom="2px solid transparent"
                        _hover={{ borderBottomColor: 'teal.500' }}
                        fontWeight="bold"
                        fontSize={{ base: 'md', md: 'lg' }}
                        color="teal.500"
                        bg="gray.100"
                        px={4}
                        py={1}
                        borderRadius="md"
                        display="inline-block"
                        maxWidth="100%"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {item.gameName}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center" mt={2}>
                      <Text fontWeight="bold" fontSize={{ base: 'sm', md: 'md' }}>Date:</Text>
                      <Text
                        bg={'yellow.100'}
                        isTruncated={!showDetails}
                        px={2}
                        py={1}
                        borderWidth="2px"
                        borderRadius="md"
                        fontSize={{ base: 'sm', md: 'md' }}
                      >
                        {formatDate(item.createdAt)}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center" mt={2}>
                      <Text fontWeight="bold" fontSize={{ base: 'sm', md: 'md' }}>
                        {item.gameName === 'Colour Shuffle' ? 'Colour' : 'Number'}:
                      </Text>
                      <Text
                        bg={'#D1D8C5'}
                        isTruncated={!showDetails}
                        px={2}
                        py={1}
                        borderWidth="2px"
                        borderRadius="md"
                        fontSize={{ base: 'sm', md: 'md' }}
                      >
                        {renderSelectedNumbers(item.selectedNumbers, item.gameName)}
                      </Text>
                    </Flex>

                    <Flex justifyContent="space-between" alignItems="center" mt={2}>
                      <Text fontWeight="bold" fontSize={{ base: 'sm', md: 'md' }}>Total Amount:</Text>
                      <Text
                        bg={'purple.100'}
                        px={2}
                        py={1}
                        borderWidth="2px"
                        borderRadius="md"
                        fontSize={{ base: 'sm', md: 'md' }}
                      >
                        ₹{item.betAmount}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center" mt={2}>
                      <Text fontWeight="bold" fontSize={{ base: 'sm', md: 'md' }}>Status:</Text>
                      <Text
                        bg={'#B4E380'}
                        isTruncated={!showDetails}
                        px={2}
                        py={1}
                        borderWidth="2px"
                        borderRadius="md"
                        fontSize={{ base: 'sm', md: 'md' }}
                      >
                        {item.status}
                      </Text>
                    </Flex>
                    {item.betType ? (
                      <Flex justifyContent="space-between" alignItems="center" mt={2}>
                        <Text fontWeight="bold" fontSize={{ base: 'sm', md: 'md' }}>betType</Text>
                        <Text
                          bg={getBackgroundColor(item.betType)}
                          isTruncated={!showDetails}
                          px={2}
                          py={1}
                          borderWidth="2px"
                          borderRadius="md"
                          fontSize={{ base: 'sm', md: 'md' }}
                        >
                          {item.betType}
                        </Text>
                      </Flex>
                    ) : null}
                    <Flex justifyContent="space-between" alignItems="center" mt={2}>
                      <Text fontWeight="bold" fontSize={{ base: 'sm', md: 'md' }}>Result:</Text>
                      <Text
                        bg={item.betResult === 'Win' ? 'green.200' : 'red.100'}
                        px={2}
                        py={1}
                        borderWidth="2px"
                        borderRadius="md"
                        fontSize={{ base: 'sm', md: 'md' }}
                      >
                        {item.betResult}
                      </Text>
                    </Flex>

                    {item.betResult === 'Win' && (
                      <Flex justifyContent="space-between" alignItems="center" mt={2}>
                        <Text fontWeight="bold" fontSize={{ base: 'sm', md: 'md' }}>Winning Amount:</Text>
                        <Text>₹{item.winningAmount}</Text>
                      </Flex>
                    )}
                    <Box mt={4}>
                      <Flex justifyContent="space-between" alignItems="center" mt={2}>
                        {renderMessage(item.betResult)}
                        {item.betResult === 'Win' ? (
                          <Icon as={FaTrophy} color="yellow.400" boxSize={6} />
                        ) : (
                          <Icon as={FaFrown} color="red.400" boxSize={6} />
                        )}
                      </Flex>
                    </Box>
                    {renderSelectedNumbers(item.selectedNumbers, item.gameName).length > 50 && (
                      <Button mt={2} bg={'yellow.200'} size="sm" _hover={{ bg: "yellow.100" }} onClick={toggleDetails}>
                        {showDetails ? 'Show Less' : 'Show More'}
                      </Button>
                    )}
                  </Box>
                </motion.div>
              </GridItem>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default BetHistory;
